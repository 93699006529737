var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.id == null ? "New Edge Device" : ("Edge Device ID " + _vm.id)))]),_c('v-card-text',[_c('v-form',{ref:"edgeDeviceFormElm",model:{value:(_vm.allFieldsValid),callback:function ($$v) {_vm.allFieldsValid=$$v},expression:"allFieldsValid"}},[_c('v-container',[(_vm.id != null)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Name","disabled":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1):_vm._e(),(_vm.id != null)?_c('v-row',{staticClass:"mt-1"},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Edge setup command","hint":"Execute the command to setup Edge device","value":_vm.edgeSetupCommand,"persistent-hint":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":_vm.copyToClipboardEdgeCommand}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,false,285571742)},[_c('span',[_vm._v("Copy to Clipboard")])])],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Edge Device Comment","hint":"Max characters allowed is 512","rules":[
                function (v) { return v == null ||
                  v == '' ||
                  (v && v.length <= 512) ||
                  'Comment should not exceed 512 characters.'; } ],"auto-grow":"","rows":"1","autocomplete":"","clearable":"","counter":"","filled":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Local IP Address","value":_vm.localIpAddress,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 7 && v.length < 16) ||
                  'IP Address must be min 7 and max 15 chars long.'; } ],"clearable":""},on:{"input":function($event){_vm.localIpAddress = $event !== '' ? $event : null},"keypress":function($event){return _vm.isNumber($event)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Public IP Address","value":_vm.publicIpAddress,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 7 && v.length < 16) ||
                  'IP Address must be min 7 and max 15 chars long.'; } ],"clearable":""},on:{"input":function($event){_vm.publicIpAddress = $event !== '' ? $event : null},"keypress":function($event){return _vm.isNumber($event)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"SSH Port","value":_vm.sshPort,"rules":[
                function (v) { return v == null ||
                  (v && v > 0 && v <= 65536) ||
                  'Value must be between 0 and 65536'; } ],"clearable":"","type":"number","step":"1"},on:{"input":function($event){_vm.sshPort = $event !== '' ? $event : null},"keypress":function($event){return _vm.isInteger($event)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Mac Address","value":_vm.macAddress,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 7 && v.length < 18) ||
                  'Mac Address must be min 7 and max 17 chars long.'; },
                _vm.isValidMACAddress ],"clearable":""},on:{"input":function($event){_vm.macAddress = $event !== '' ? $event : null}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Username","autocomplete":"new-username","value":_vm.username,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 3 && v.length < 50) ||
                  'Must be atleast 3 and max 50 characters long.'; } ],"clearable":""},on:{"input":function($event){_vm.username = $event !== '' ? $event : null}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Password","autocomplete":"new-password","value":_vm.password,"rules":[
                function (v) { return v == null ||
                  (v && v.length >= 3 && v.length < 50) ||
                  'Must be atleast 3 and max 50 characters long.'; } ],"clearable":"","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off'},on:{"input":function($event){_vm.password = $event !== '' ? $event : null},"click:append":function($event){_vm.showPassword = !_vm.showPassword}}})],1)],1),(_vm.id != null && _vm.edgeDeviceTypeId)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-list-item',{staticClass:"pl-0",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Edge AI Inference Device Type Model "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openBenchmarkingSheet.apply(null, arguments)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,false,186515447)},[_vm._v(" View Benchmarking Sheet ")])],1),_c('v-list-item-subtitle',{staticClass:"text-h6 pl-2"},[_vm._v(_vm._s(_vm.edgeDeviceTypeId))])],1)],1)],1)],1):_vm._e(),(
            _vm.existingEdgeDeviceDetails &&
            _vm.existingEdgeDeviceDetails.is_shared_org_level_device
          )?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Shared Edge Device heartbeat interval (in seconds)","required":"","type":"number","step":"1","hint":"\n                  All Edge devices must send heartbeats to the backend at this\n                  configured interval in seconds (between 5 to 300 seconds) otherwise\n                  they will be considerted as offline and edge offline alert will be\n                  generated. Edge devices shared across multiple parking lots such as\n                  this device requies the heartbeat interval to be configured on the\n                  device level which will override the value configured on the parking\n                  lot options.\n              ","persistent-hint":"","min":"15","max":"300","rules":[
                function (v) { return v <= 300 ||
                  'Heartbeat interval cannot be longer than 300 seconds'; },
                function (v) { return v >= 15 ||
                  'Heartbeat interval cannot be lesser than 15 second'; } ]},on:{"focus":function($event){return $event.target.select()},"keypress":function($event){return _vm.isInteger($event)}},model:{value:(
                _vm.sharedOrgLevelEdgeDeviceInferenceHeartbeatIntervalSeconds
              ),callback:function ($$v) {
                _vm.sharedOrgLevelEdgeDeviceInferenceHeartbeatIntervalSeconds
              =_vm._n($$v)},expression:"\n                sharedOrgLevelEdgeDeviceInferenceHeartbeatIntervalSeconds\n              "}})],1)],1):_vm._e(),(
            _vm.existingEdgeDeviceDetails &&
            _vm.existingEdgeDeviceDetails.is_shared_org_level_device
          )?_c('v-row',[_c('v-col',[_c('v-banner',{staticClass:"mt-1 mb-6",attrs:{"color":"blue lighten-5","rounded":""}},[_c('div',{staticClass:"d-flex align-start"},[_c('v-icon',{attrs:{"slot":"icon","color":"blue","size":"24"},slot:"icon"},[_vm._v(" mdi-information-outline ")]),_c('div',{staticClass:"ml-4"},[_c('div',[_vm._v(" This is a shared Edge node so it required a device level heartbeat interval configured on it. This heartbeat interval overrides the value configured on the parking lot options of each parking lot in which this device is added. ")])])],1)])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.pipelineType.shouldShowPipelineTypeChangedWarning),callback:function ($$v) {_vm.$set(_vm.pipelineType, "shouldShowPipelineTypeChangedWarning", $$v)},expression:"pipelineType.shouldShowPipelineTypeChangedWarning"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 grey lighten-2"},[_vm._v(" Please Restart this Edge Device ")]),_c('v-card-text',{staticClass:"pa-4"},[_vm._v(" You have changed the type of AI processing on this device. You must also restart this Edge Device now from the Allxon edge devices management dashboard after you save this change. ")]),_c('v-divider')],1)],1),_c('v-select',{attrs:{"items":_vm.pipelineType.items,"item-text":"name","item-value":"value","label":"Device AI Processing Type","hint":"Select the type of AI processing that should run on this edge device.","hide-no-data":"","append-icon":"mdi-cog"},on:{"change":_vm.onPipelineTypeChange},model:{value:(_vm.pipelineType.selected),callback:function ($$v) {_vm.$set(_vm.pipelineType, "selected", $$v)},expression:"pipelineType.selected"}})],1)],1),(
            _vm.pipelineType.shouldShowPipelineTypeChangedWarning &&
            _vm.assignedCameraIds.length > 0
          )?_c('v-row',[_c('v-col',[_c('v-banner',{attrs:{"two-line":"","color":"orange lighten-5"}},[_vm._v(" Warning, this edge device is currently processing streams from "+_vm._s(_vm.assignedCameraIds.length)+" cameras. You have changed the type of AI processing on this device, this will cause the current camera assignment will be removed. ")])],1)],1):_vm._e(),(_vm.existingEdgeDeviceDetails != null)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.camerasInLot,"item-text":"name","item-value":"id","label":"Select Edge Cameras Assigned to this Device","hide-no-data":"","hide-details":"","hint":"Select the cameras that are to be assigned to this device for processing.","append-icon":"mdi-server","clearable":"","multiple":"","chips":"","deletable-chips":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-checkbox',{attrs:{"value":_vm.assignedCameraIds.includes(data.item.id)}}),_vm._v(" "+_vm._s(data.item.name)+" - ID "+_vm._s(data.item.id)+" (currently "+_vm._s(data.item.edge_device_id ? ("assigned to " + (data.item.edge_device_id)) : data.item.lpr_edge_device_id ? ("assigned to " + (data.item.lpr_edge_device_id)) : "unassigned")+") ")]}}],null,false,3486379908),model:{value:(_vm.assignedCameraIds),callback:function ($$v) {_vm.assignedCameraIds=$$v},expression:"assignedCameraIds"}})],1)],1):_vm._e(),(_vm.existingEdgeDeviceDetails)?_c('v-row',[_c('v-col',[_c('v-banner',{staticClass:"mt-1 mb-6",attrs:{"color":_vm.isCameraAssignmentExceedingDeviceLimit
                  ? 'orange lighten-5'
                  : 'blue lighten-5',"rounded":""}},[_c('div',{staticClass:"d-flex align-start"},[(_vm.isCameraAssignmentExceedingDeviceLimit)?_c('v-icon',{attrs:{"slot":"icon","color":"orange","size":"24"},slot:"icon"},[_vm._v(" mdi-alert-outline ")]):_c('v-icon',{attrs:{"slot":"icon","color":"blue","size":"24"},slot:"icon"},[_vm._v(" mdi-information-outline ")]),_c('div',{staticClass:"ml-4"},[(_vm.isCameraAssignmentExceedingDeviceLimit)?_c('div',{staticClass:"mb-2 font-weight-bold"},[_vm._v(" You have selected "+_vm._s(_vm.assignedCameraIds.length)+" cameras ")]):_vm._e(),(
                      _vm.existingEdgeDeviceDetails &&
                      _vm.existingEdgeDeviceDetails.is_shared_org_level_device
                    )?_c('div',[_vm._v(" This is a shared Edge node with "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.assignedCameraIds.length)+" other cameras ")]),_vm._v(" assigned to this device. ")]):_vm._e(),(_vm.existingEdgeDeviceDetails)?_c('div',[_vm._v(" This device can support up to "+_vm._s(_vm.existingEdgeDeviceDetails.max_supported_streams_count)+" camera streams @ 720p. ")]):_vm._e()])],1)])],1)],1):_vm._e(),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"text-body-1 pt-2"},[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_vm._v(" Plate Recognizer Edge Integration ")]),_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue-grey lighten-3"},on:{"click":function($event){return _vm.showInfoPopup(
                          'Plate Recognizer Edge Integration',
                          "Enable the toggle to  set up Plate Recognizer Edge Integration. \n                          This will allow you to use Plate Recognizer API for license plate recognition setup with an Edge device. \n                          Set the Token and License Key here.<br><br>\n                          Find Token and License Key at <a href='https://app.platerecognizer.com/accounts/login/' target='_blank'>Plate Recognizer Config</a><br><br>\n                          Find complete setup instructions here: <a href='http://docs.spotgenius.com/1.0/using-spotgenius/plate-recognizer-edge-integration' target='_blank'>Plate Recognizer Edge Device Setup</a>"
                        )}}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Info")])])],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"justify-left align-center pt-2 pb-2 ml-1"},[_c('v-switch',{staticClass:"ma-0",attrs:{"inset":"","hide-details":""},model:{value:(_vm.plateReconizer.enabled),callback:function ($$v) {_vm.$set(_vm.plateReconizer, "enabled", $$v)},expression:"plateReconizer.enabled"}}),_c('span',{staticClass:"v-label theme--light text-cursor"},[_vm._v("Enable Plate Recognizer Edge Integration Options")])],1)],1)],1),(_vm.plateReconizer.enabled)?_c('v-row',[_c('v-col',{staticClass:"pt-1 pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Plate Recognizer Token","rules":[
                    function (v) { return (v && v.length >= 4 && v.length < 256) ||
                      'Plate Recognizer Token must be between 4 to 256 characters long.'; } ]},model:{value:(_vm.plateReconizer.token),callback:function ($$v) {_vm.$set(_vm.plateReconizer, "token", $$v)},expression:"plateReconizer.token"}})],1)],1):_vm._e(),(_vm.plateReconizer.enabled)?_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Plate Recognizer License Key","rules":[
                    function (v) { return (v && v.length >= 4 && v.length < 256) ||
                      'Plate Recognizer License Key must be between 4 to 256 characters long.'; } ]},model:{value:(_vm.plateReconizer.license),callback:function ($$v) {_vm.$set(_vm.plateReconizer, "license", $$v)},expression:"plateReconizer.license"}})],1)],1):_vm._e()],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeForm}},[_vm._v("Close")]),_c('v-btn',{attrs:{"disabled":(_vm.comment != null && _vm.comment.length > 512) || !_vm.allFieldsValid,"color":"primary","text":""},on:{"click":_vm.submitForm}},[_vm._v("Save")])],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.infoPopup.show),callback:function ($$v) {_vm.$set(_vm.infoPopup, "show", $$v)},expression:"infoPopup.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.infoPopup.title)+" ")]),_c('v-card-text',{staticClass:"pa-4",domProps:{"innerHTML":_vm._s(_vm.infoPopup.message)}}),_c('v-divider')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }