import { Point, Polygon, FeatureCollection } from "geojson";
import { ParkingSpot, ParkingStatus } from "./ParkingSpot";
import { ParkingZone } from "./ParkingZones";
import { ParkingLane } from "./ParkingLane";
import { Camera } from "./Camera";
import { ParkingHistory } from "./ParkingHistory";
import { ParkingZoneUntrackedInfoOnly } from "./ParkingZones";
import { PaginatedItems } from "./Common";
import { ParkingPermit } from "./ParkingPermit";
import { ParkingLotNvrDetails } from "./NvrDetails";
import { SpecialArea } from "./SpecialArea";
import { VehicleDetails } from "./PrivateLotUser";
import { ParkingLotMaintenance } from "./Maintenance";

export enum ParkingOperations {
  spot_based_24_hours_free_parking = "spot_based_24_hours_free_parking",
  lpr_based_24_hours_free_parking = "lpr_based_24_hours_free_parking",
  paid_24_hours = "paid_24_hours",
  specify_lpr_based_paid_parking_time = "specify_lpr_based_paid_parking_time",
}

interface ParkingLotBase {
  name: string;
  address: string;
  gps_coordinates: Point;
  polygon: Polygon;
  map?: FeatureCollection;
  run_inference: boolean;
  is_private: boolean;
  max_park_minutes: number | null;
  congestion_threshold: number | null;
  tag_names?: Array<string>;
  organization_id: number;

  is_security_feature_enabled: boolean;
  is_illegal_activity_detection_enabled: boolean;
  illegal_activity_detection_start_time: string | null;
  illegal_activity_detection_end_time: string | null;
  illegal_activity_detection_vehicle_count_threshold: number | null;
  is_illegal_activity_detection_set_initial_vehicle_count: boolean;
  illegal_activity_detection_vehicles_in_lot: number;

  is_illegal_parking_violation_feature_enabled: boolean;
  violation_alert_grace_period_seconds: number;

  is_parking_permit_feature_enabled: boolean;
  is_spot_visibility_checking_feature_enabled: boolean;
  is_accuracy_tracking_feature_enabled: boolean;
  is_extreme_weather_detection_feature_enabled: boolean;
  is_garbled_image_detection_feature_enabled: boolean;
  is_park_my_car_feature_enabled: boolean;
  is_double_parking_feature_enabled: boolean;
  double_parking_violation_wait_time_seconds: number;
  is_ev_charger_violation_feature_enabled: boolean;
  ev_violation_check_interval_minutes: number;
  ev_violation_count_for_alert: number;
  is_show_app_user_info_enabled: boolean;
  is_search_parked_car_owner_enabled: boolean;
  is_show_blocking_app_user_info_enabled: boolean;
  wait_for_user_to_save_spot: number;
  camera_offline_alert_delay_threshold_minutes: number | null;
  inference_heartbeat_interval_seconds: number;
  is_parking_history_visible: boolean;
  is_notification_on_spot_update_enabled: boolean;
  is_pull_apis_feature_enabled: boolean;
  is_enforceability_push_apis_feature_enabled: boolean;
  is_availability_push_apis_feature_enabled: boolean;
  is_revenue_feature_enabled: boolean;
  is_sg_connect_feature_enabled: boolean;
  revenue_options: any | null;

  illegal_activity_detection_week_start_time: Array<string | null> | null;
  illegal_activity_detection_week_end_time: Array<string | null> | null;

  counter_zone_reset_vehicle_count_start_schedule_week_crontab: Array<string> | null;

  lot_image_path: string | null;
  thumbnail_lot_image_path: string | null;

  operator_contact_number: string | null;

  max_scheduled_reports: number;
  max_scheduled_reports_recipients: number;

  timezone: string | null;
  parking_operations: ParkingOperations;

  qr_tamper_detection_enabled: boolean;
  qr_detection_times: Array<string>;
  qr_tamper_threshold: number;
}

export interface ParkingLotCreate extends ParkingLotBase {
  parking_spots?: Array<ParkingSpot>;
  organization_id: number;
  is_lpr_feature_enabled?: boolean;
  is_lpr_alert_list_feature_enabled: boolean;
  lpr_pair_linking_interval_minutes: number;
  lpr_number_plate_text_matching_distance_thresh: number;
  lpr_duplicate_record_ignore_window_seconds: number;
  is_anpr_feature_enabled: boolean;
  is_anpr_feature_visible_to_customers: boolean;
  anpr_matching_window_interval_minutes: number;
  anpr_inactive_cleanup_interval_minutes: number;
  anpr_vehicle_matching_threshold: number;
  anpr_lpr_event_delay_offset_seconds: number;
  is_anpr_rectify_delayed_lpr_enabled: boolean;
  qr_tamper_detection_enabled: boolean;
  qr_detection_times: Array<string>;
  qr_tamper_threshold: number;
}

export interface ParkingLotUpdate extends ParkingLotBase {
  camera_map?: string | null;
}

export interface ParkingLot extends ParkingLotBase {
  id: number;
  polygon_center: Point | null;
  parking_spots: Array<ParkingSpot>;
  parking_zones: Array<ParkingZone>;
  special_areas: Array<SpecialArea>;
  parking_lanes: Array<ParkingLane>;
  parking_permits: Array<ParkingPermit> | null;
  cameras: Array<Camera>;
  created_at?: Date;

  nvr_details: Array<ParkingLotNvrDetails>;
  is_unknown_perception_feature_enabled: boolean;

  is_lpr_feature_enabled: boolean;
  is_lpr_alert_list_feature_enabled: boolean;
  lpr_pair_linking_interval_minutes: number;
  lpr_duplicate_record_ignore_window_seconds: number;
  is_anpr_feature_enabled: boolean;
  is_anpr_feature_visible_to_customers: boolean;
  anpr_matching_window_interval_minutes: number;
  anpr_inactive_cleanup_interval_minutes: number;
  anpr_vehicle_matching_threshold: number;
  anpr_lpr_event_delay_offset_seconds: number;
  is_anpr_rectify_delayed_lpr_enabled: boolean;

  lot_image_path_url: string | null;
  thumbnail_lot_image_path_url: string | null;
  flock_lpr_external_network_id: string | null;

  maintenance_ongoing: boolean;
  maintenance_meta: ParkingLotMaintenance | null;

  num_free_parking_spots: number;
  num_unavailable_parking_spots: number;
  num_total_parking_spots: number;
}

export interface DigimapResponse {
  map?: FeatureCollection;
  parking_lot_id: number;
  parking_zone_id?: number | null;
  parking_spots: Array<ParkingSpot>;
  parking_zones: Array<ParkingZone>;
}

export interface UpdateDigimap {
  map?: FeatureCollection;
}

export interface ParkingLotMapStats {
  name: string;
  free_count: number;
  total_count: number;
}

export interface ParkingLotBasic extends ParkingLotBase {
  id: number;
  cameras: Array<Camera>;
  organization_id: number;
  is_schedule_active: boolean;
  is_lpr_feature_enabled: boolean;
  is_lpr_alert_list_feature_enabled: boolean;
  lpr_pair_linking_interval_minutes: number;
  lpr_number_plate_text_matching_distance_thresh: number;
  lpr_duplicate_record_ignore_window_seconds: number;

  is_anpr_feature_enabled: boolean;
  is_anpr_feature_visible_to_customers: boolean;
  anpr_matching_window_interval_minutes: number;
  anpr_inactive_cleanup_interval_minutes: number;
  anpr_vehicle_matching_threshold: number;
  anpr_lpr_event_delay_offset_seconds: number;
  is_anpr_rectify_delayed_lpr_enabled: boolean;

  organization_name?: string;
  lot_image_path_url: string | null;
  thumbnail_lot_image_path_url: string | null;
}

export interface ParkingLotOccupancy {
  current_status: string;
  count: number;
}

export interface CameraSpotCounts {
  camera_id: number | null;
  count: number;
}

export interface ParkingSpotsIds {
  id: number;
  name?: string;
}

export interface ParkedVehicleDetails {
  vehicle_model_name?: string;
  license_plate_number: string;
}

export interface ParkingSpotSavedEndUserDetails {
  spot_id: number;
  end_user_id: number;
  name?: string;
  email: string;
  contact_number?: string;
  tenant?: string;
  parked_vehicle?: ParkedVehicleDetails;
}

export interface ParkingLocationSavedEndUserDetails {
  id: number;
  gps_coordinates: Point | string;
  end_user_id: number;
  end_user_name: string;
  license_plate_number: string;
  end_user_contact: string;
  estimated_parking_minutes: number;
  blocked_spots: Array<number>;
  parked_at: Date;
}

export interface ParkingLotStats {
  updated_at: Date;
  saved_details_updated_at: Date;
  spots: Array<ParkingSpotsIds>;
  occupancy: Array<ParkingLotOccupancy>;
  updates: PaginatedItems<ParkingHistory>;
  zones: Array<ParkingZone>;
  all_camera_spot_counts: Array<CameraSpotCounts>;
  refined_camera_spot_counts: Array<CameraSpotCounts>;
  congested: boolean;
}

export interface ParkingLotSavedDetails {
  saved_spots: Array<ParkingSpotSavedEndUserDetails>;
  saved_locations: Array<ParkingLocationSavedEndUserDetails>;
}

export interface ParkingLotZoneOccupancyCount {
  ts: number;
  parking_zone_id: number;
  occ_count: number;
  tot_count: number;
  tot_camera_mapped_spots: number;
  local_time: Date;
}

export interface ParkingLotDwellTime {
  ts: number;
  local_time: Date;
  time_0: number;
  time_1to15: number;
  time_15to60: number;
  time_1hour: number;
  time_2hour: number;
  time_3hour: number;
  time_4plushour: number;
}

export interface ParkingLotTurnover {
  ts: number;
  local_time: Date;
  parking_sessions: number;
  number_of_spots: number;
  turnover: number;
}

export interface ParkingLotAppUsageCount {
  ts: number;
  created_at_localtime: Date;
  unique_end_users_count: number;
}

export interface ParkingLotTenantOccupancy {
  tenant_id: number;
  max_occupied_spots: number;
  num_threshold_exceeded: number;
  occupied_spots_threshold: number;
  local_time: Date;
  ts: number;
}

export interface ParkingLotUntrackedInfoOnly {
  id: number;
  name: string;
  address: string;
  parking_zones: Array<ParkingZoneUntrackedInfoOnly>;
}

export interface ParkingLotNameCheck {
  parking_lot_name: string;
}

export enum ViolationOccured {
  yes = "yes",
  no = "no",
  maybe = "maybe",
}

export interface PermitNames {
  name: string;
  icon_symbol: string;
  is_restricted: boolean;
  expires_at?: Date;
}

export interface EnforcementSpotReport {
  spot_id: number;
  spot_name: string | null;
  spot_status: string;
  spot_permits?: Array<PermitNames>;
  occupied_at?: Date;
  end_user_email?: string;
  license_plate_number?: Array<string>;
  end_user_permits?: Array<PermitNames>;
  violation_occured: ViolationOccured;
  image?: string;
}

export interface ParkingLotOnlyId {
  id: number;
  name: string;
}

export interface EnforcementReport {
  data: Array<EnforcementSpotReport>;
  all_parking_lot_ids: Array<ParkingLotOnlyId>;
  all_permits_names: Array<PermitNames>;
}

interface EndUserDetails {
  end_user_email: string;
  license_plate_number?: Array<string>;
  end_user_permits?: Array<PermitNames>;
}

export interface VerifyLicensePlateResponse {
  spot_id: number;
  message: string;
  success: boolean;
  end_user_details?: EndUserDetails;
}

export interface ParkingLotsList {
  id: number;
  name: string;
  is_private: boolean;
}

export interface ParkingLotUploadedImagePaths {
  lot_image_path: string | null;
  thumbnail_lot_image_path: string | null;
  lot_image_path_url: string | null;
  thumbnail_lot_image_path_url: string | null;
}

export interface ParkingLotHistory {
  id: number;
  parking_spot_id: number;
  status: string;
  status_changed_manually: boolean;
  flipflop_status: string | null;
  unknown_status: string | null;
  was_status_marked_unknown: boolean;
  vehicle_parking_usage_anpr_record_id: number | null;
  license_plate_detected: string | null;
  created_at: Date;
}

export interface ParkingLotDashboardOverview {
  num_lots: number;
  total_spots: number;
  occupied_spots: number;
  num_violations: number;
  camera_issues: number;
}

export interface ParkingLotDashboardDwellTime {
  time_0: number;
  time_1to15: number;
  time_15to30: number;
  time_30to60: number;
  time_1hour: number;
  time_2hour: number;
  time_3plushour: number;
  parking_sessions: number;
}

export interface ParkingLotDashboardViolations {
  id: number;
  title: string;
  details: string;
  created_at: Date;
  alert_state: string;
  alert_type_id: number;
}

export interface ParkingLotDashboardViolationsGrouped {
  title: string;
  title_count: number;
}

export interface ParkingLotDashboardViolationsResponse {
  num_violations: number;
  violations: Array<ParkingLotDashboardViolations>;
  violations_grouped: Array<ParkingLotDashboardViolationsGrouped>;
}

interface TimezoneHeader {
  header: string;
}

export interface AllTimezones {
  group?: string;
  title?: string;
  current_time?: string;
  value?: string;
  props?: TimezoneHeader;
}
