var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.isEmailPreferences ? ("Email Preferences " + (_vm.existingOrgUserDetails != null ? ("- " + (_vm.existingOrgUserDetails.email)) : "")) : _vm.isChangePassword ? "Change Password" : _vm.orgUserId == null ? "New Org User" : ("User ID " + _vm.orgUserId)))]),_c('v-card-text',[_c('v-form',{ref:"orgUserFormElm",attrs:{"autocomplete":"off"},model:{value:(_vm.allFieldsValid),callback:function ($$v) {_vm.allFieldsValid=$$v},expression:"allFieldsValid"}},[_c('v-container',[(!_vm.isChangePassword && !_vm.isEmailPreferences)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Name","hint":"Name of the Org User","rules":[
                function (v) { return (v.length >= 3 && v.length < 50) ||
                  'Name must be between 3 to 50 characters long.'; },
                _vm.noSpecialChars ],"required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1):_vm._e(),(!_vm.isEmailPreferences)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"autocomplete":"off","label":"Email","hint":"Login Email ID","rules":[_vm.validateEmail],"required":"","disabled":_vm.isChangePassword},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1):_vm._e(),(!_vm.isEmailPreferences)?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"autocomplete":"off","label":_vm.isChangePassword
                  ? 'Enter old password'
                  : _vm.isEditingExistingUser
                  ? 'Enter Password here to Reset'
                  : 'Enter a password',"hint":_vm.isChangePassword
                  ? 'Enter old password'
                  : _vm.isEditingExistingUser
                  ? 'Enter Password here to Reset'
                  : 'Enter a password',"rules":_vm.passwordRules(_vm.isChangePassword),"counter":_vm.password && _vm.password.length,"error-messages":_vm.isChangePassword ? _vm.oldPasswordErrors : _vm.passwordErrors,"type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1):_vm._e(),(_vm.isChangePassword && !_vm.isEmailPreferences)?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"autocomplete":"off","label":"Enter new password","hint":"Enter new password","rules":_vm.passwordRules(false),"counter":_vm.newPassword && _vm.newPassword.length,"error-messages":_vm.passwordErrors,"type":_vm.showNewPassword ? 'text' : 'password',"append-icon":_vm.showNewPassword ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.showNewPassword = !_vm.showNewPassword}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1)],1):_vm._e(),(!_vm.isChangePassword && !_vm.isEmailPreferences)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Address","hint":"Address for the user."},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)],1):_vm._e(),(!_vm.isChangePassword && !_vm.isEmailPreferences)?_c('v-row',[_c('v-col',{staticClass:"text-body-1",attrs:{"cols":"12"}},[(_vm.orgId == _vm.superAdminOrgId)?_c('span',[_vm._v("User Type: SuperAdmin")]):(_vm.orgId == _vm.technicianOrgId)?_c('span',[_vm._v("User Type: Technician")]):_c('v-select',{attrs:{"items":_vm.userTypeItems,"item-text":"name","item-value":"value","label":"User Type","required":""},model:{value:(_vm.orgUserType),callback:function ($$v) {_vm.orgUserType=$$v},expression:"orgUserType"}})],1)],1):_vm._e(),(
            _vm.orgUserType &&
            _vm.orgId != _vm.superAdminOrgId &&
            _vm.orgUserType != 'superadmin' &&
            _vm.orgUserType != 'operator' &&
            _vm.orgUserType != 'technician' &&
            _vm.orgUserType != 'developer' &&
            !_vm.isEmailPreferences
          )?_c('v-row',{staticClass:"pa-0"},[_c('v-col',[_c('v-switch',{staticClass:"custom-switch",attrs:{"color":"primary","label":"Allow Editing Parking Lot Options"},on:{"change":_vm.handleOptionSettingsToggle},model:{value:(_vm.isOptionSettingsEnable),callback:function ($$v) {_vm.isOptionSettingsEnable=$$v},expression:"isOptionSettingsEnable"}})],1)],1):_vm._e(),(_vm.isEmailPreferences)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"color":"success","inset":"","label":"Receive Alert Emails"},model:{value:(_vm.emailPreferences.receiveAlertEmails),callback:function ($$v) {_vm.$set(_vm.emailPreferences, "receiveAlertEmails", $$v)},expression:"emailPreferences.receiveAlertEmails"}})],1)],1):_vm._e(),(_vm.showEmailPreferences)?_c('v-divider'):_vm._e(),(_vm.showEmailPreferences)?_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"pb-0"},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Select Alert Emails you would like to receive:")])])],1):_vm._e(),(_vm.showEmailPreferences)?_c('v-row',{staticClass:"ml-2"},[_c('v-col',{staticClass:"pt-0"},_vm._l((_vm.emailPreferences.allAlertTypes),function(item,index){return _c('v-checkbox',{key:item['id'],attrs:{"hide-details":"","label":item['title']},model:{value:(_vm.emailPreferences.receiveAlertEmailTypes[index]),callback:function ($$v) {_vm.$set(_vm.emailPreferences.receiveAlertEmailTypes, index, $$v)},expression:"emailPreferences.receiveAlertEmailTypes[index]"}})}),1)],1):(_vm.emailPreferences.receiveAlertEmails && _vm.loadingAlertTypes)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()],1)],1)],1),_c('v-card-actions',[(
        _vm.existingOrgUserDetails != null &&
        !_vm.isChangePassword &&
        !_vm.isEmailPreferences &&
        _vm.orgUserType != 'operator' &&
        _vm.orgUserType != 'technician' &&
        _vm.orgUserType != 'developer'
      )?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.showUserEmailPreferences}},[_vm._v("Edit Email Preferences")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeForm}},[_vm._v("Close")]),_c('v-btn',{attrs:{"disabled":!_vm.allFormFieldsValid || _vm.loadingAlertTypes,"color":"primary","text":""},on:{"click":_vm.submitForm}},[_vm._v("Save")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }