























































































































































import Vue from "vue";
import api from "@/api/api";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import Chart, { ChartItem, ChartConfiguration } from "chart.js/auto";
import { ParkingLot } from "@/api/models";
import { getTodaysDate } from "@/libs/dateUtils";

export interface ChartDataset {
  label: string;
  data: Array<number>;
  borderColor: string;
  backgroundColor: string;
  barPercentage: number;
}

export default Vue.extend({
  name: "AppUsageChart",
  props: {
    lotId: {
      type: Number,
      required: true,
    },
    parkingLotData: {
      type: Object as () => ParkingLot,
      required: true,
    },
    parkingLotName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      appUsageChart: null as Chart<"bar"> | null,
      chart: {
        isLoading: false,
        showStartDate: false,
        startDate: "",
        showEndDate: false,
        endDate: "",
        granularity: [
          { label: "5 Minutes", value: 60 * 5 },
          { label: "15 Minutes", value: 60 * 15 },
          { label: "30 Minutes", value: 60 * 30 },
          { label: "60 Minutes", value: 60 * 60 },
          { label: "Daily", value: 60 * 60 * 24 },
          { label: "Weekly", value: 60 * 60 * 24 * 7 },
          // { label: "Monthly", value: 60 * 5 * 24 * 30 },
        ],
        selectedGranularity: 60 * 30,
        duration: [
          { label: "1 Day", value: 0 },
          { label: "1 Week", value: 1 },
          { label: "1 Month", value: 2 },
          { label: "Last Month", value: 3 },
          { label: "Custom", value: 4 },
        ],
        selectedDuration: 0,

        type: "bar",
        data: {
          labels: [] as Array<string>,
          datasets: [] as Array<ChartDataset>,
        },
        options: {
          // maintainAspectRatio: true,
          responsive: true,
          tooltips: {
            intersect: false,
          },
          scales: {
            y: {
              title: {
                display: true,
                text: "Unique user count",
              },
            },
            x: {
              title: {
                display: true,
                text: "Date and Time",
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: "Number of unique visits from App",
            },
            legend: {
              position: "top",
            },
          },
        },
      },
    };
  },
  mounted() {
    this.chart.startDate = this.todaysDate;
    this.chart.endDate = this.todaysDate;

    this.setFiltersFromURLParams();

    this.fetchAppUsageChartData();
  },
  methods: {
    setFiltersFromURLParams() {
      const duration = Number(this.$route.query.duration);
      if (duration && typeof duration == "number") {
        this.chart.selectedDuration = duration;
        this.setChartDates();
        this.setChartGranularity();
      }
      const granularity = Number(this.$route.query.granularity);
      if (granularity && typeof granularity == "number") {
        this.chart.selectedGranularity = granularity;
      }
    },
    initializeChart() {
      const ctx = document.getElementById("app-usage-chart") as ChartItem;
      this.appUsageChart = new Chart(
        ctx,
        this.chart as ChartConfiguration<"bar">
      );
    },
    async fetchAppUsageChartData() {
      if (this.appUsageChart == null) {
        this.setChartDates();
        this.initializeChart();
      }
      this.chart.isLoading = true;
      this.chart.data.labels = [];
      this.chart.data.datasets = [];

      try {
        let appUsageData = await api.getAppUsageStats(
          this.lotId,
          this.chart.startDate,
          this.chart.endDate,
          this.chart.selectedGranularity
        );

        let datasets = [] as Array<ChartDataset>;
        if (appUsageData) {
          const granularity = this.chart.granularity.find(
            (g) => g.value === this.chart.selectedGranularity
          )?.label;
          const diffDays = this.getDateDiffInDays(
            this.chart.startDate,
            this.chart.endDate
          );
          let timestamps = [] as Array<string>;
          let data = [] as Array<number>;

          let all_dates = [] as Array<string>;
          if (this.chart.selectedGranularity == 60 * 60 * 24) {
            let currentDate = new Date(this.chart.startDate);
            let endDate = new Date(this.chart.endDate);
            while (currentDate <= endDate) {
              all_dates.push(
                new Date(currentDate).toLocaleString("en-us", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                  weekday: "long",
                })
              );
              currentDate.setDate(currentDate.getDate() + 1);
            }

            timestamps = [...all_dates];
            data = all_dates.map((v) => 0);

            console.log("All Dates: ", all_dates);
          }

          for (let row of appUsageData) {
            const d = new Date(row.created_at_localtime);
            if (this.chart.selectedGranularity > 60 * 60) {
              if (this.chart.selectedGranularity != 60 * 60 * 24) {
                timestamps.push(
                  `${d.toLocaleString("en-us", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                    weekday: "long",
                  })}`
                );
              }
              this.chart.options.scales.x.title.text = "Date";
              if (this.chart.selectedGranularity === 60 * 60 * 24)
                this.chart.options.scales.x.title.text = "Each Day";
              else if (this.chart.selectedGranularity === 60 * 60 * 24 * 7)
                this.chart.options.scales.x.title.text = "Each Week";
            } else if (diffDays < 1) {
              timestamps.push(
                `${d.toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
                })}`
              );
              this.chart.options.scales.x.title.text = `Date - ${new Date(
                `${this.chart.endDate} 23:59`
              ).toLocaleString("en-us", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                weekday: "long",
              })} (Every ${granularity})`;
            } else {
              timestamps.push(
                `${d.toLocaleString("en-US", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  weekday: "long",
                  hour12: true,
                })}`
              );
              this.chart.options.scales.x.title.text = "Date and Time";
            }
          }

          const timestampsMapped = [...new Set(appUsageData.map((o) => o.ts))];
          for (let ts of timestampsMapped) {
            const appUsages = appUsageData.filter((o) => o.ts === ts);
            let unique_end_users_count = 0;
            for (let appUsageRecord of appUsages) {
              unique_end_users_count += appUsageRecord.unique_end_users_count;
            }
            if (this.chart.selectedGranularity == 60 * 60 * 24) {
              const day_date: string = new Date(ts * 1000).toLocaleString(
                "en-us",
                {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                  weekday: "long",
                }
              );
              const index = timestamps.indexOf(day_date);
              if (index > -1) {
                data[index] = unique_end_users_count;
              }
            } else {
              data.push(unique_end_users_count);
            }
          }
          datasets.push({
            label: this.parkingLotName
              ? this.parkingLotName
              : this.parkingLotData
              ? this.parkingLotData.name
              : "Lot",
            data,
            borderColor: "#FFFFFF",
            backgroundColor: this.generateRandomColor(this.lotId),
            barPercentage: 1.0,
          });
          data = [];
          timestamps = timestamps.filter(
            (item, index) => timestamps.indexOf(item) === index
          );
          this.chart.data.labels = timestamps;
          this.chart.data.datasets = datasets;
        }
      } catch (e) {
        console.log("Erro getting data:", e);
        this.$dialog.message.error(
          "Error, unable to load latest data. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } finally {
        if (this.appUsageChart) this.appUsageChart.update();
        this.chart.isLoading = false;
      }
    },
    setChartDates() {
      const today = new Date();
      let start_date = new Date();
      switch (this.chart.selectedDuration) {
        case 0:
          this.chart.startDate = this.todaysDate;
          break;
        case 1:
          start_date = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 6
          );
          this.chart.startDate =
            start_date.getFullYear() +
            "-" +
            ("0" + (start_date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + start_date.getDate()).slice(-2);
          break;
        case 2:
          start_date = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 29
          );
          this.chart.startDate =
            start_date.getFullYear() +
            "-" +
            ("0" + (start_date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + start_date.getDate()).slice(-2);
          break;
        case 3:
          this.chart.startDate = this.convertDate(
            new Date(today.getFullYear(), today.getMonth() - 1, 1)
          );
          this.chart.endDate = this.convertDate(
            new Date(today.getFullYear(), today.getMonth(), 0)
          );
          break;
        default:
          start_date = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 6
          );
          this.chart.startDate =
            start_date.getFullYear() +
            "-" +
            ("0" + (start_date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + start_date.getDate()).slice(-2);
          break;
      }
      if (this.chart.selectedDuration !== 3) {
        this.chart.endDate = this.todaysDate;
      }

      this.chart.granularity = [
        { label: "5 Minutes", value: 60 * 5 },
        { label: "15 Minutes", value: 60 * 15 },
        { label: "30 Minutes", value: 60 * 30 },
        { label: "60 Minutes", value: 60 * 60 },
        { label: "Daily", value: 60 * 60 * 24 },
        { label: "Weekly", value: 60 * 60 * 24 * 7 },
      ].filter((g, i) => {
        switch (this.chart.selectedDuration) {
          case 0:
            return [1, 2, 3].includes(i);
          case 1:
            return [4].includes(i);
          case 2:
            return [4, 5].includes(i);
          case 3:
            return [4, 5].includes(i);
          default:
            return [3, 4, 5].includes(i);
        }
      });
      this.chart.selectedGranularity = this.chart.granularity[0].value;
      if (this.chart.selectedDuration == 0) {
        this.chart.selectedGranularity = 60 * 30;
      } else if (this.chart.selectedDuration == 1) {
        this.chart.selectedGranularity = 60 * 60 * 24;
      }
      this.setChartGranularity();
    },
    setChartGranularity() {
      if (this.chart.selectedDuration === 4) {
        const diffDays = this.getDateDiffInDays(
          this.chart.startDate,
          this.chart.endDate
        );
        if (diffDays > 1) {
          this.chart.selectedGranularity = 60 * 60 * 24;
          if (diffDays <= 7)
            this.chart.granularity = [{ label: "Daily", value: 60 * 60 * 24 }];
          else {
            this.chart.granularity = [
              { label: "Daily", value: 60 * 60 * 24 },
              { label: "Weekly", value: 60 * 60 * 24 * 7 },
            ];
          }
        } else {
          this.chart.granularity = [
            { label: "15 Minutes", value: 60 * 15 },
            { label: "30 Minutes", value: 60 * 30 },
            { label: "60 Minutes", value: 60 * 60 },
          ];
          this.chart.selectedGranularity = 60 * 30;
        }
      }
    },
    getDateDiffInDays(startDate: string, endDate: string) {
      const diffTime = Math.abs(
        new Date(endDate).getTime() - new Date(startDate).getTime()
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    generateRandomColor(num: number) {
      const colors = [
        "Yellow",
        "Blue",
        "Red",
        "Green",
        "Black",
        "Brown",
        "Azure",
        "Ivory",
        "Teal",
        "Silver",
        "Purple",
        "Navy blue",
        "Pea green",
        "Gray",
        "Orange",
        "Maroon",
        "Charcoal",
        "Aquamarine",
        "Coral",
        "Fuchsia",
        "Wheat",
        "Lime",
        "Crimson",
        "Khaki",
        "Hot pink",
        "Magenta",
        "Olden",
        "Plum",
        "Olive",
        "Cyan",
      ];
      return colors[num % 30];
    },
    convertDate(date: Date) {
      const yyyy = date.getFullYear().toString();
      const mm = (date.getMonth() + 1).toString();
      const dd = date.getDate().toString();

      const mmChars = mm.split("");
      const ddChars = dd.split("");

      return (
        yyyy +
        "-" +
        (mmChars[1] ? mm : "0" + mmChars[0]) +
        "-" +
        (ddChars[1] ? dd : "0" + ddChars[0])
      );
    },
    refreshPage() {
      // this.chart.startDate = this.todaysDate;
      // this.chart.endDate = this.todaysDate;
      // this.chart.selectedGranularity = 60 * 30;
      // this.chart.selectedDuration = 0;
      // this.setChartDates();
      this.fetchAppUsageChartData();
    },
    getLocalDate(d: Date) {
      return dayjs(d).format("YYYY-MM-DD");
    },
  },
  computed: {
    ...mapGetters("user", ["isLoggedIn", "hasAccessLevelDashboardMonitoring"]),
    todaysDate() {
      return getTodaysDate();
    },
  },
});
