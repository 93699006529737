import { ParkingLot, SessionData } from "@/api/models";
import sgconnectapis from "@/api/sgconnectapis";

const state = {
  currentParkingLotData: null as ParkingLot | null,
  sgConnectToken: "",
  orgID: 0,
};

const getters = {
  getCurrentParkingLotData(state: SessionData): ParkingLot | null {
    return state.currentParkingLotData;
  },
};

const mutations = {
  setSgConnectToken(state: any, sgConnectToken: string): void {
    state.sgConnectToken = sgConnectToken;
  },
  setOrgID(state: any, orgID: number): void {
    state.orgID = orgID;
  },
};

const actions = {
  async generateTokenApi(context: any, org_id: any): Promise<void> {
    const response = await sgconnectapis.generateToken(org_id);
    context.commit("setSgConnectToken", response?.token ?? "");
    context.commit("setOrgID", org_id);
    return context.state.sgConnectToken;
  },

  async parkingTimingApi(
    context: any,
    { parking_lot_id, req_data }: any
  ): Promise<any | null> {
    const response = await sgconnectapis.parkingTiming(
      parking_lot_id,
      req_data,
      context.state.sgConnectToken
    );

    return response;
  },

  async getParkingTiming(context: any, id: number): Promise<any | null> {
    const response = await sgconnectapis.getParkingTiming(
      id,
      context.state.sgConnectToken,
      context.state.orgID
    );
    return response;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
