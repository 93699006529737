























































































































































































import Vue from "vue";
import axios from "axios";

import { mapGetters, mapMutations, mapActions } from "vuex";

import ConfirmActionDialog from "@/components/common/ConfirmActionDialog.vue";

interface BotMessage {
  id: string;
  text: string;
  isBot: boolean;
  loaded: boolean;
  isFeedbackGiven: boolean;
  isGoodResponse: boolean;
}

enum ButtonVariant {
  FLAT = "flat",
  TEXT = "text",
  ELEVATED = "elevated",
  TONAL = "tonal",
  OUTLINED = "outlined",
  PLAIN = "plain",
}

interface ActionItems {
  label: string;
  action: string;
  variant?: ButtonVariant;
}

export default Vue.extend({
  name: "ChatBot",

  components: {
    ConfirmActionDialog,
  },

  data() {
    return {
      expanded: false,
      showActionItems: null as number | string | null,
      messages: [] as Array<BotMessage>,
      newMessage: "",
      loadingBotResponse: false,
      confirmAction: {
        show: false,
        title: "Close Valet bot ?",
        message:
          "If you close Valet bot, it will reset and you will lose all your conversation.",
        actions: [
          { label: "Close Valet Bot", action: "confirm", variant: "plain" },
          { label: "Cancel", action: "close", variant: "text" },
        ] as ActionItems[],
      },
    };
  },

  computed: {
    ...mapGetters("user", ["isLoggedIn", "getCurrentUserData"]),
    ...mapGetters("chatbot", ["getAssistantId", "getThreadId"]),

    expandIcon(): string {
      return this.expanded ? "mdi-arrow-collapse" : "mdi-arrow-expand";
    },
    expandCollapseText(): string {
      return this.expanded ? "Collapse" : "Expand";
    },
    userName(): string {
      return this.getCurrentUserData?.name.split(" ")[0];
    },
    chatbotThreadId(): string | null {
      return this.getThreadId;
    },
    chatbotAssistantId(): string | null {
      return this.getAssistantId;
    },
  },

  methods: {
    ...mapActions("chatbot", [
      "initializeChatbot",
      "sendMessage",
      "submitFeedback",
      "resetThread",
    ]),
    toggleExpand(): void {
      this.expanded = !this.expanded;
      this.saveChatbotState();
      this.$emit("toggle-expand");
    },
    streamResponse(message_id: string): void {
      if (!this.chatbotThreadId || !this.chatbotAssistantId) {
        console.error("Chatbot thread ID or assistant ID not found");
        return;
      }

      let callOnce = false;
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Valet Chatbot: Token not found");
        return;
      }
      axios
        .get(
          `${process.env.VUE_APP_VALET_API_URL}/api/v1/chat/stream/${this.chatbotThreadId}/${this.chatbotAssistantId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            onDownloadProgress: (evt: any) => {
              if (!callOnce) {
                this.loadingBotResponse = false;
                this.messages.push({
                  id: message_id,
                  text: "",
                  isBot: true,
                  loaded: false,
                  isFeedbackGiven: false,
                  isGoodResponse: false,
                });
                callOnce = true;
              }

              // console.log("Valet Chatbot: Download progress, ", evt);
              let data = evt.target.response;
              this.parseChatbotResponse(data);
            },
          }
        )
        .then((response) => {
          // console.log("Valet Chatbot: Connection set"); //, response.data);
          this.messages[this.messages.length - 1].loaded = true;
          this.saveChatbotState();
          // const eventSource = new EventSource(
          //   `${import.meta.env.VITE_VALET_API_URL}/api/v1/chat/stream/${chatbotThreadId.value}/${chatbotAssistantId.value}`
          // );
          // eventSource.onmessage = function (event) {
          //   const data = JSON.parse(event.data);
          //   console.log("Valet Chatbot: New message, ", data);
          // };
          // eventSource.onerror = function (error) {
          //   console.error("Valet Chatbot: EventSource failed, ", error);
          //   eventSource.close();
          // };
        })
        .catch((error) => {
          console.error("Valet Chatbot: Error during setup, ", error);
        });
    },
    parseChatbotResponse(message: string): void {
      const contentArray = message
        .split("\n") // Split the message by new line
        .filter((line) => line.startsWith('data: {"content":')) // Filter only content lines
        .map((line) => {
          const json = JSON.parse(line.replace("data: ", "")); // Remove "data: " and parse JSON
          return json.content; // Extract the content value
        });
      const content = contentArray.join(""); // Join the content lines
      // console.log('Valet Chatbot response:', content);

      this.messages[this.messages.length - 1].text = content;
    },
    scrollToBottom(): void {
      setTimeout(() => {
        const chatbotMessageContainer = document.querySelector(
          ".chatbot-message-container"
        );
        if (chatbotMessageContainer) {
          chatbotMessageContainer.scrollTop =
            chatbotMessageContainer.scrollHeight;
        }
      }, 500);
    },
    async sendMessageToChatbot(): Promise<void> {
      this.loadingBotResponse = true;

      // scroll to bottom
      this.scrollToBottom();

      this.newMessage = this.newMessage.trim();
      if (this.newMessage) {
        this.messages.push({
          id: `user-message-${this.messages.length + 1}`,
          text: this.newMessage,
          isBot: false,
          loaded: true,
          isFeedbackGiven: false,
          isGoodResponse: false,
        });
        this.saveChatbotState();

        const response = await this.sendMessage(this.newMessage);
        if (response) {
          this.streamResponse(response.id);
          this.newMessage = "";
          this.saveChatbotState();
        }
      }
    },
    handleEnter(event: KeyboardEvent): void {
      if (
        this.newMessage.trim() === "" ||
        this.newMessage === null ||
        this.newMessage.length > 300
      ) {
        // Prevent sending empty message or message with more than 300 characters
        return;
      }
      if (event.shiftKey) {
        // Allow new line
        return;
      } else {
        // Prevent default behavior and send message
        // event.preventDefault();
        this.sendMessageToChatbot();
      }
      this.saveChatbotState();
    },
    copyMessage(text: string): void {
      navigator.clipboard.writeText(text);
      this.$dialog.message.info("Message copied to clipboard", {
        position: "top-right",
        timeout: 3000,
      });
    },
    thumbsUp(message_id: string): void {
      this.submitFeedback(message_id, true, "good response");
      const messageIndex = this.messages.findIndex(
        (message) => message.id === message_id
      );
      if (messageIndex >= 0) {
        this.messages[messageIndex].isGoodResponse = true;
        this.messages[messageIndex].isFeedbackGiven = true;
      }
      this.saveChatbotState();
      this.$dialog.message.info("Thank you for your feedback", {
        position: "top-right",
        timeout: 3000,
      });
    },
    thumbsDown(message_id: string): void {
      this.submitFeedback(message_id, false, "bad response");
      const messageIndex = this.messages.findIndex(
        (message) => message.id === message_id
      );
      if (messageIndex >= 0) {
        this.messages[messageIndex].isGoodResponse = false;
        this.messages[messageIndex].isFeedbackGiven = true;
      }
      this.saveChatbotState();
      this.$dialog.message.info("Thank you for your feedback", {
        position: "top-right",
        timeout: 3000,
      });
    },
    clearHistory(): void {
      this.newMessage = "";
      this.messages = [];
      this.resetThread();
      this.saveChatbotState();
    },
    closeChat(): void {
      this.loadingBotResponse = false;
      this.expanded = false;
      this.clearHistory();
      this.saveChatbotState();
      this.$emit("toggle-chatbot");
    },
    confirmCloseChat(): void {
      if (this.messages.length <= 0) {
        this.closeChat();
        return;
      }
      this.confirmAction.show = true;
    },
    takeConfirmAction(action: string): void {
      if (action == "confirm") {
        this.closeChat();
      }
      this.confirmAction.show = false;
    },
    formatNewMessage(): void {
      this.newMessage = this.newMessage.trim();
      this.saveChatbotState();
    },
    loadChatbotState(): void {
      const chatbotExpanded = localStorage.getItem("chatbot_expanded");
      if (chatbotExpanded) {
        this.expanded = true;
      }
      const chatbotQuery = localStorage.getItem("chatbot_query");
      if (chatbotQuery) {
        this.newMessage = chatbotQuery;
      }
      const chatbotMessages = localStorage.getItem("chatbot_messages");
      if (chatbotMessages) {
        this.messages = JSON.parse(chatbotMessages);
      }
    },
    saveChatbotState(): void {
      if (this.expanded) {
        localStorage.setItem("chatbot_expanded", "true");
      } else {
        localStorage.removeItem("chatbot_expanded");
      }
      if (this.newMessage.trim().length > 0) {
        localStorage.setItem("chatbot_query", this.newMessage);
      } else {
        localStorage.removeItem("chatbot_query");
      }
      if (this.messages.length > 0) {
        localStorage.setItem("chatbot_messages", JSON.stringify(this.messages));
      } else {
        localStorage.removeItem("chatbot_messages");
      }
    },
  },

  mounted() {
    this.loadChatbotState();
    this.initializeChatbot();
  },

  updated() {
    // focus on input field
    const chatbotMessageInputField = document.getElementById(
      "chatbot-message-input-field"
    );
    if (chatbotMessageInputField) {
      chatbotMessageInputField.focus();
    }
  },
});
