import type {
  ChatbotHealthResponse,
  ChatbotAssistantIdResponse,
  ChatbotThreadResponse,
  ChatbotSuccessResponse,
  ChatbotMessageResponse,
} from "@/api/models";
import api from "@/api/api";

interface ChatbotSessionData {
  assistantName: string | null;
  assistantId: string | null;
  threadId: number | null;
  threadIdOpenAI: string | null;
}

const state = {
  assistantName: null as string | null,
  assistantId: null as string | null,
  threadId: null as number | null,
  threadIdOpenAI: null as string | null,
};

const getters = {
  getAssistantName(state: ChatbotSessionData): string | null {
    return state.assistantName;
  },
  getAssistantId(state: ChatbotSessionData): string | null {
    return state.assistantId;
  },
  getThreadId(state: ChatbotSessionData): number | null {
    return state.threadId;
  },
  getThreadIdOpenAI(state: ChatbotSessionData): string | null {
    return state.threadIdOpenAI;
  },
  isReady(state: ChatbotSessionData): boolean {
    return state.assistantId !== null && state.threadId !== null;
  },
};

const mutations = {
  setAssistantDetails(
    state: ChatbotSessionData,
    payload: { assistantName: string; assistantId: string }
  ): void {
    state.assistantName = payload.assistantName;
    state.assistantId = payload.assistantId;
  },
  setThreadsId(
    state: ChatbotSessionData,
    payload: { threadId: number; threadIdOpenAI: string }
  ): void {
    const { threadId, threadIdOpenAI } = payload;
    state.threadIdOpenAI = threadIdOpenAI;
    state.threadId = threadId;
    if (threadId) {
      localStorage.setItem("threadId", threadId.toString());
    } else {
      localStorage.removeItem("threadId");
    }
  },
  setThreadId(state: ChatbotSessionData, threadId: number): void {
    state.threadId = threadId;
    if (threadId) {
      localStorage.setItem("threadId", threadId.toString());
    } else {
      localStorage.removeItem("threadId");
    }
  },
  resetThreadId(state: ChatbotSessionData): void {
    state.threadId = null;
    state.threadIdOpenAI = null;
    localStorage.removeItem("threadId");
  },
  resetChatbot(state: ChatbotSessionData): void {
    state.assistantName = null;
    state.assistantId = null;
    state.threadId = null;
    state.threadIdOpenAI = null;
    localStorage.removeItem("threadId");
  },
};

const actions = {
  async fetchHealth(context: any): Promise<ChatbotHealthResponse | null> {
    return await api.chatbotHealthCheck();
  },
  async fetchAssistantId(context: any): Promise<string | null> {
    const response: ChatbotAssistantIdResponse | null =
      await api.chatbotGetAssistantId();
    if (response) {
      context.commit("setAssistantDetails", {
        assistantName: response.name,
        assistantId: response.openai_assistant_id,
      });
    }
    return response ? response.openai_assistant_id : null;
  },
  async initNewThread(context: any): Promise<number | null> {
    const response: ChatbotThreadResponse | null =
      await api.chatbotCreateThread();
    if (response) {
      context.commit("setThreadsId", {
        threadId: response.id,
        threadIdOpenAI: response.openai_thread_id,
      });
      localStorage.setItem("threadId", response.id.toString());
    }
    return response ? response.id : null;
  },
  async initLastUserThread(context: any): Promise<number | null> {
    const response: ChatbotThreadResponse | null =
      await api.chatbotGetLatestUserThread();
    if (response) {
      context.commit("setThreadsId", {
        threadId: response.id,
        threadIdOpenAI: response.openai_thread_id,
      });
    }
    return response ? response.id : null;
  },
  async deleteThread(context: any): Promise<boolean> {
    localStorage.removeItem("threadId");

    if (!state.threadId) return false;
    const response: ChatbotSuccessResponse | null =
      await api.chatbotDeleteThread(state.threadId);
    if (response) {
      context.commit("resetThreadId");

      if (response.status === "success") {
        return true;
      }
    }
    return false;
  },
  async sendMessage(
    context: any,
    message: string
  ): Promise<ChatbotMessageResponse | null> {
    if (!state.threadId) {
      const threadId = await context.dispatch("initNewThread");
      if (threadId) {
        context.commit("setThreadId", threadId);
      } else {
        return null;
      }
    }
    if (!state.threadId) return null;
    const response = await api.chatbotSendMessage(state.threadId, message);
    return response;
  },

  async submitFeedback(
    context: any,
    payload: { message_id: string; is_thumbs_up: boolean; feedback: string }
  ): Promise<ChatbotSuccessResponse | null> {
    const { message_id, is_thumbs_up, feedback } = payload;
    if (!state.threadId) return null;
    const response = await api.chatbotMessageFeedback(
      state.threadId,
      message_id,
      is_thumbs_up,
      feedback
    );
    return response;
  },

  async initializeChatbot(context: any): Promise<void> {
    const healthResponse = await context.dispatch("fetchHealth");
    if (healthResponse) {
      // && healthResponse.status === 'healthy') {
      const assistantId = await context.dispatch("fetchAssistantId");
      if (assistantId) {
        const threadId = await context.dispatch("initLastUserThread");
        if (!threadId) {
          await context.dispatch("initNewThread");
        }
      }
    }
  },
  resetChatbot(context: any): void {
    context.dispatch("deleteThread");
    context.commit("resetChatbot");
  },
  resetThread(context: any): void {
    context.dispatch("deleteThread");
    context.commit("resetThreadId");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
