import { PaginatedItems } from "./Common";
import { ParkingLotOnlyId } from "./ParkingLot";
import { VehicleParkingUsageTrackingAssociationDetails } from "./VehicleParkingUsageTrackingAssociation";

export enum AlertType {
  info = "info",
  error = "error",
}

export enum AlertAction {
  no_action = "no_action",
  update_camera_map = "update_camera_map",
}

export enum AlertState {
  open = "open",
  closed = "closed",
}

export enum Severity {
  high = "high",
  medium = "medium",
  low = "low",
}

export enum AlertCategory {
  infrastructure = "infrastructure",
  violation = "violation",
  system_error = "system_error",
  informational_events = "informational_events",
}

export enum AlertTriggerState {
  active = "active",
  inactive = "inactive",
  unsure = "unsure",
}

export interface Alert {
  id: number;
  title: string;
  details?: string;
  severity: Severity;
  category: AlertCategory;
  alert_type?: AlertType;
  alert_action: AlertAction;
  alert_state: AlertState;
  organization_id: number;
  parking_lot_id?: number;
  parking_spot_id?: number;
  special_area_id?: number;
  parking_zone_id?: number;
  parking_lot_name?: string;
  parking_spot_name?: string;
  parking_zone_name?: string;

  is_private_lot?: boolean;
  camera_id?: number;
  image_path?: string;
  image_path_url?: string;
  image_paths?: Array<string> | null;
  image_paths_urls?: Array<string> | null;
  vehicle_occupied_image_path?: string | null;
  vehicle_occupied_image_path_url?: string | null;
  old_map_frame_path?: string;
  created_at: Date;
  vehicle_occupied_at?: Date | null;
  state_updated_at: Date;
  state_updated_by_org_user_id: number;
  visible_to_customers?: boolean;
  visible_to_all_superadmins?: boolean;
  issue_id?: number;
  issue_url?: string;
  incident_count: number | null;
  duration_minutes: number | null;
  without_delay_created_at: Date;
  archived_at: Date | null;

  vehicle_parking_usage_anpr_record_id: number | null;
  anpr_record: VehicleParkingUsageTrackingAssociationDetails | null;
}

export interface CameraId {
  id: number;
}

export interface AzureUsers {
  displayName?: string;
  mailAddress?: string;
}

export interface AlertsPaginated {
  all_lot_ids: Array<ParkingLotOnlyId>;
  all_camera_ids: Array<CameraId>;
  all_alert_types: Array<AlertTypes>;
  all_alerts: PaginatedItems<Alert>;
  high_severity_count: number;
  medium_severity_count: number;
  low_severity_count: number;
  azure_users_list?: Array<AzureUsers>;
}

export interface AlertTypes {
  id: number;
  title: string;
  severity?: Severity;
  category: AlertCategory;
}

export interface GenerateAlert {
  parking_lot_id: number;
  severity: string;
  category: string;
  details: string;
}

export interface CheckEscalatedAlert {
  issue_id: number;
  devops_organization: string;
  devops_project: string;
}
