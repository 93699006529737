

































































































































































































































































































































































































































































































import Vue from "vue";
import api from "@/api/api";
import dayjs from "dayjs";
import Chart, { ChartItem, ChartConfiguration } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  VehicleMatchingRequestQueue,
  VehicleMatchingRequestQueueUpdate,
  VehicleMatchingRequestStatus,
  VehicleMatchingRequestQueueResponse,
} from "@/api/models/VehicleMatchingRequestQueue";

import {
  EvInferenceRequestQueue,
  EvInferenceRequestStatus,
  EvInferenceRequestQueueUpdate,
} from "@/api/models";
import VehicleMatchingAnprRecordDetails from "./VehicleMatchingAnprRecordDetails.vue";
import { ParkingLotsList, CamerasList } from "@/api/models";
import _ from "lodash";
import { getTodaysDate } from "@/libs/dateUtils";

export interface ChartDataset {
  label: string;
  data: Array<number>;
  borderColor: string;
  backgroundColor: string;
}
export interface enabledLots {
  id: number;
  name: string;
}

export interface filterInfo {
  label: string;
  value: number;
}

export default Vue.extend({
  components: { VehicleMatchingAnprRecordDetails },
  name: "VehicleMatchingAnprAccuracyChart",

  props: {
    licensePlate: {
      required: false,
      type: String,
    },
    lprId: {
      required: false,
      type: Number,
    },
    filterTimestamp: {
      required: false,
      type: String,
    },
    matchingInterval: {
      required: false,
      type: Number,
    },
    filterSpot: {
      required: false,
      type: Number,
    },
    filterStartTimestamp: {
      required: false,
      type: String,
    },
    filterEndTimestamp: {
      required: false,
      type: String,
    },
  },

  data() {
    return {
      isLoading: false,
      vehicleMatchingRecords: [] as Array<VehicleMatchingRequestQueue>,
      matchingAccuracyData: [] as Array<Record<string, any>>,
      selectedRecord: null as EvInferenceRequestQueue | null,
      selectedRecordCandidateDetails:
        null as VehicleMatchingRequestQueueResponse | null,
      showDetailsPopup: false,
      pagination: {
        itemsTotalCount: 0,
        page: 1,
        itemsPerPage: 20,
      },
      filters: {
        record_id: null as number | null,
        record_id_match: "equal_to" as string,
        top_matching_score: null as number | null, //
        top_matching_score_match: "equal_to" as string, //

        conf_output_score_greater_than: 0 as number,
        conf_output_score_lesser_than: 1.0 as number,
        output_verification_result: {
          items: [
            { label: "All", value: "all" },
            { label: "All Correct", value: "all_correct" },
            { label: "All Incorrect", value: "all_incorrect" },
            { label: "Some Correct", value: "some_correct" },
            { label: "Some Incorrect", value: "Some_incorrect" },
          ],
          selected: "all",
        },
        request_status: null as string | null,
        lotId: null as number | null,
        cameraId: null as number | null,
        spotId: null as number | null,
        is_verified: false,
        ai_predicted_correctly: null as boolean | null,
        ai_predicted_incorrectly: null as boolean | null,

        lpr_id: null as number | null,
        license_plate_number: null as string | null,
        verified: null as boolean | null,
        ai_predicted: null as boolean | null,
        ai_matched_correctly: null as boolean | null,
        matching_status: null as string | null,
        matching_type: null as string | null,
        spot_id: null as number | null,
        order_by: null as string | null,

        duration: {
          showStartDate: false,
          showStartTime: false,
          startDate: "",
          startTime: "00:00",
          showEndDate: false,
          showEndTime: false,
          endDate: "",
          endTime: "23:59",
          granularity: [
            { label: "15 Minutes", value: 60 * 15 },
            { label: "Daily", value: 60 * 60 * 24 },
            { label: "Weekly", value: 60 * 60 * 24 * 7 },
            { label: "Monthly", value: 60 * 60 * 24 * 30 },
          ],
          selectedGranularity: 60 * 60 * 24 * 7, //WEEKLY
          duration: [
            { label: "1 Day", value: 0 },
            { label: "1 Week", value: 1 },
            { label: "1 Month", value: 2 },
            { label: "Last Month", value: 3 },
            { label: "Custom", value: 4 },
          ],
          selectedDuration: 0,
        },
      },
      headers: [
        { text: "Record ID", value: "id", sortable: false },
        {
          text: "Request Status",
          value: "request_status",
          sortable: false,
        },
        { text: "Verified", value: "is_verified", sortable: false },
        {
          text: "Verification Results",
          value: "flagged_incorrect",
          sortable: false,
        },
        { text: "Created At", value: "created_at", sortable: false },
      ],

      parkingLots: {
        items: [] as Array<ParkingLotsList>,
      },

      cameras: {
        items: [] as Array<CamerasList>,
      },

      trendChartObj: null as Chart<"line"> | null,
      trendChart: {
        type: "line",
        data: {
          labels: [] as Array<string>,
          datasets: [] as Array<Record<string, any>>,
        },

        plugins: [ChartDataLabels],
        options: {
          plugins: {
            title: {
              display: true,
              text: "ANPR Vehicle Matching Accuracy",
            },
            legend: {
              position: "top",
            },
            tooltip: {
              intersect: false,
              mode: "index",
              titleColor: "#DF5286",
              titleFont: { weight: "bold" },
            },
            datalabels: {
              display: (context: any) => {
                return context.dataset.hidden;
              },
            },
          },
        },
      },

      dataRecords: [] as Array<EvInferenceRequestQueue> | null,

      // CHART
      AllParkingAccuracyChart: null as Chart<"line"> | null,
      chart: {
        isLoading: false,
        lotId: null,
        cameraId: null,
        showStartDate: false,
        showStartTime: false,
        startDate: "",
        startTime: "00:00",
        showEndDate: false,
        showEndTime: false,
        endDate: "",
        endTime: "23:59",
        granularity: [
          { label: "15 Minutes", value: 60 * 15 },
          { label: "Daily", value: 60 * 60 * 24 },
          { label: "Weekly", value: 60 * 60 * 24 * 7 },
          { label: "Monthly", value: 60 * 60 * 24 * 30 },
        ],
        selectedGranularity: 60 * 60 * 24 * 7, //WEEKLY
        duration: [
          { label: "1 Day", value: 0 },
          { label: "1 Week", value: 1 },
          { label: "1 Month", value: 2 },
          { label: "Last Month", value: 3 },
          { label: "Custom", value: 4 },
        ],
        selectedDuration: 0,
        selectedOccupancyType: 1,
        occupanyType: [
          { label: "Maximum Occupancy", value: 1 },
          { label: "Minimum Occupancy", value: 0 },
        ],
        type: "line",
        labels: [], //x axis labels --> in our case dates
        data: {
          labels: [] as Array<Array<string>>,
          datasets: [] as Array<ChartDataset>,
        },
        options: {
          // responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              title: {
                display: true,
                text: "Metric %",
              },
              grace: 1,
            },
            x: {
              title: {
                display: true,
                text: "Date and Time",
              },
              grid: {
                color: ["#D3D3D3"],
                lineWidth: 2,
              },
              ticks: {
                color: ["rgb(0,0,0)"],
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: "Model Accuracy",
            },
            legend: {
              position: "top",
            },
            tooltip: {
              intersect: false,
              mode: "index",
              titleColor: "#DF5286",
              titleFont: { weight: "bold" },
            },
            datalabels: {
              clip: false,
              align: "top",
              formatter: function (value: any, context: any) {
                return Math.round(value) + "%";
              },
              font: {
                size: "14px",
                weight: "bold",
              },
            },
          },
        },
        plugins: [ChartDataLabels],
      },
    };
  },
  mounted() {
    this.chart.startDate = this.todaysDate;
    this.chart.endDate = this.todaysDate;

    if (this.licensePlate) {
      this.filters.license_plate_number = this.licensePlate;
      if (this.filterTimestamp) {
        this.chart.selectedDuration = 4;

        const localStartDateTime = dayjs(this.filterTimestamp);
        this.chart.startDate = localStartDateTime.format("YYYY-MM-DD");
        this.chart.startTime = localStartDateTime.format("HH:mm");

        const localEndDateTime = localStartDateTime.add(
          this.matchingInterval || 10,
          "minutes"
        );
        this.chart.endDate = localEndDateTime.format("YYYY-MM-DD");
        this.chart.endTime = localEndDateTime.format("HH:mm");
      }
    }
    if (this.lprId) {
      this.filters.lpr_id = this.lprId;
    }
    if (this.filterSpot) {
      this.chart.selectedDuration = 4;
      this.filters.spot_id = this.filterSpot;
      if (this.filterStartTimestamp) {
        const [date, time] = this.filterStartTimestamp.split(" ");
        this.chart.startDate = date;
        this.chart.startTime = time;
      }
      if (this.filterEndTimestamp) {
        const [date, time] = this.filterEndTimestamp.split(" ");
        this.chart.endDate = date;
        this.chart.endTime = time;
      }
    }

    this.fetchUserLots();
    this.fetchRecords(1);
  },
  methods: {
    initializeChart() {
      const ctx = document.getElementById(
        "all-parkings-accuracy-chart"
      ) as ChartItem;
      this.AllParkingAccuracyChart = new Chart(ctx, this.chart as any);
    },
    async fetchUserLots() {
      let parkingLots = await api.getAllParkingLotsListForUser();
      if (parkingLots) {
        this.parkingLots.items = parkingLots;
      }
      if (this.chart.lotId) {
        this.fetchLotCameras();
      }
    },
    async fetchLotCameras() {
      if (this.chart.lotId != null) {
        let cameras = await api.getAllCamerasList(this.chart.lotId);
        if (cameras) {
          this.cameras.items = cameras;
        }
      }
    },
    async fetchRecords(page: number | null = null) {
      /*
      if (this.AllParkingAccuracyChart == null) {
        this.setChartDates();
        this.initializeChart();
      }*/
      this.chart.isLoading = true;
      //line names
      this.chart.data.labels = [];
      //line data points
      this.chart.data.datasets = [];

      // Convert selected dates to UTC timestamp
      let startTimestamp = dayjs(
        `${this.chart.startDate} ${this.chart.startTime}`
      )
        .utc()
        .toISOString();
      let endTimestamp = dayjs(`${this.chart.endDate} ${this.chart.endTime}`)
        .utc()
        .toISOString();
      console.log("Using UTC timestamps", startTimestamp, endTimestamp);

      console.log("Using Lot ID", this.chart.lotId);

      try {
        this.isLoading = true;
        if (page != null) {
          this.pagination.page = page;
        }
        let dataRecords = await api.getEvChargingInferenceRequests(
          startTimestamp,
          endTimestamp,
          this.filters.lotId,
          this.filters.cameraId,
          this.filters.spotId || null,
          this.filters.conf_output_score_greater_than,
          this.filters.conf_output_score_lesser_than,
          this.filters.is_verified,
          this.filters.ai_predicted_correctly,
          this.filters.ai_predicted_incorrectly,
          this.filters.request_status,
          this.filters.order_by,
          this.pagination.page,
          this.pagination.itemsPerPage
        );
        if (dataRecords != null) {
          this.dataRecords = dataRecords.items;
          this.pagination.itemsTotalCount = dataRecords.total;
        }
      } catch (e) {
        console.error(e);
        this.$dialog.message.error(
          "Error, unable to load data. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } finally {
        // if (this.AllParkingAccuracyChart) this.AllParkingAccuracyChart.update();
        this.isLoading = false;
      }

      // Restore previously selected record in newly fetched data
      if (
        this.selectedRecord &&
        this.dataRecords &&
        this.dataRecords.length > 0
      ) {
        this.selectedRecord =
          this.dataRecords.find((r) => r.id === this.selectedRecord?.id) ||
          null;
        console.log(
          "Restored previously selected record index",
          this.selectedRecord
        );
      }
    },

    initTrendsChart(chartData: Array<Record<string, string | number>>) {
      let labels = [];
      let totalCount = [];
      let unverifiedCount = [];
      let verifiedCount = [];
      let correctCount = [];
      let incorrectCount = [];
      let verifiedPercent = [];
      let accuracyPercent = [];
      let truePositiveCount = [];
      let trueNegativeCount = [];
      let falsePositiveCount = [];
      let falseNegativeCount = [];

      for (let dataPoint of chartData) {
        let dataPointVerifiedCount =
          Number(dataPoint.total_count) - Number(dataPoint.unverified_count);
        labels.push(String(dataPoint.date));
        totalCount.push(dataPoint.total_count);
        unverifiedCount.push(dataPoint.unverified_count);
        verifiedCount.push(dataPointVerifiedCount);
        correctCount.push(dataPoint.ai_matched_correctly_count);
        incorrectCount.push(dataPoint.ai_matched_incorrectly_count);
        truePositiveCount.push(
          dataPoint.ai_matched_incorrectly_true_positive_count
        );
        trueNegativeCount.push(
          dataPoint.ai_matched_incorrectly_true_negative_count
        );
        falsePositiveCount.push(
          dataPoint.ai_matched_incorrectly_false_positive_count
        );
        falseNegativeCount.push(
          dataPoint.ai_matched_incorrectly_false_negative_count
        );
        verifiedPercent.push(
          (dataPointVerifiedCount / Number(dataPoint.total_count)) * 100
        );
        if (dataPointVerifiedCount > 0) {
          accuracyPercent.push(
            (Number(dataPoint.ai_matched_correctly_count) /
              dataPointVerifiedCount) *
              100
          );
        } else {
          accuracyPercent.push(0);
        }
      }

      this.trendChart.data.labels = labels;
      this.trendChart.data.datasets = [
        {
          label: "Accurate Percentage",
          data: accuracyPercent,
          hidden: false,
          borderColor: this.generateRandomColor(0),
        },
        {
          label: "Verified Percentage",
          data: verifiedPercent,
          hidden: false,
          borderColor: this.generateRandomColor(1),
        },
        {
          label: "Total Count",
          data: totalCount,
          hidden: false,
          borderColor: this.generateRandomColor(2),
        },
        {
          label: "Verified Count",
          data: verifiedCount,
          hidden: false,
          borderColor: this.generateRandomColor(3),
        },
        {
          label: "Unverified Count",
          data: unverifiedCount,
          hidden: false,
          borderColor: this.generateRandomColor(4),
        },
        {
          label: "Correct Count",
          data: correctCount,
          hidden: false,
          borderColor: this.generateRandomColor(5),
        },
        {
          label: "Incorrect Count",
          data: incorrectCount,
          hidden: false,
          borderColor: this.generateRandomColor(6),
        },
        {
          label: "True Positive Count",
          data: truePositiveCount,
          hidden: false,
          borderColor: this.generateRandomColor(7),
        },
        {
          label: "True Negative Count",
          data: trueNegativeCount,
          hidden: false,
          borderColor: this.generateRandomColor(8),
        },
        {
          label: "False Positive Count",
          data: falsePositiveCount,
          hidden: false,
          borderColor: this.generateRandomColor(7),
        },
        {
          label: "False Negative Count",
          data: falseNegativeCount,
          hidden: false,
          borderColor: this.generateRandomColor(8),
        },
      ];

      if (this.trendChartObj == null) {
        // Init new chart obj if loading for the first time
        const ctx = document.getElementById("trend-chart") as ChartItem;
        this.trendChartObj = new Chart(ctx, this.trendChart as any);
      } else {
        // Remember which dataset lines / legend items are hidden on the existing
        // chart even after data is refreshed
        const legendsHidden = this.trendChartObj.legend?.legendItems?.map(
          (item) => item.hidden
        );
        if (
          legendsHidden != null &&
          legendsHidden.length === this.trendChart.data.datasets.length
        ) {
          for (let [i, dataset] of this.trendChart.data.datasets.entries()) {
            dataset.hidden = legendsHidden[i];
          }
        }
      }
      this.trendChartObj.update();
    },

    switchSelectedRecord(direction: number) {
      console.log("Direction: ", direction);
      if (!this.selectedRecord || !this.dataRecords) {
        console.log("No record selected.");
        return;
      }
      const currentIndex = this.dataRecords.indexOf(this.selectedRecord);
      console.log("Current index: ", currentIndex);
      const nextIndex = currentIndex + direction;
      console.log("Next index: ", nextIndex);
      if (
        nextIndex >= 0 &&
        this.vehicleMatchingRecords &&
        nextIndex < this.vehicleMatchingRecords.length
      ) {
        this.selectedRecord = this.dataRecords[nextIndex];
        console.log("Selected: ", this.selectedRecord.id);
      }
    },

    async fetchRecordDetails(record_id: number) {
      let vehicleMatchingRecord = await api.getVehicleMatchingAnprRecord(
        record_id
      );
      if (vehicleMatchingRecord) {
        this.selectedRecordCandidateDetails = vehicleMatchingRecord;
      }
    },

    showRecordDetails(record: EvInferenceRequestQueue) {
      this.selectedRecord = record;
      this.showDetailsPopup = true;
      if (record) {
        this.fetchRecordDetails(record.id);
      }
    },

    hideRecordDetails() {
      this.showDetailsPopup = false;
      this.selectedRecord = null;
    },

    setChartDates() {
      const today = new Date();
      switch (this.filters.duration.selectedDuration) {
        case 0:
          this.filters.duration.startDate = this.todaysDate;
          this.filters.duration.startTime = "00:00";
          this.filters.duration.endTime = "23:59";
          break;
        case 1:
          this.filters.duration.startDate = this.getLocalDate(
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
          );
          break;
        case 2:
          this.filters.duration.startDate = this.getLocalDate(
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() - 30
            )
          );
          break;
        case 3:
          this.filters.duration.startDate = this.convertDate(
            new Date(today.getFullYear(), today.getMonth() - 1, 1)
          );
          this.filters.duration.endDate = this.convertDate(
            new Date(today.getFullYear(), today.getMonth(), 0)
          );
          break;
        default:
          this.filters.duration.startDate = this.getLocalDate(
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
          );
          break;
      }
      if (this.filters.duration.selectedDuration !== 3) {
        this.filters.duration.endDate = this.todaysDate;
      }

      this.filters.duration.granularity = [
        { label: "5 Minutes", value: 60 * 5 },
        { label: "15 Minutes", value: 60 * 15 },
        { label: "30 Minutes", value: 60 * 30 },
        { label: "60 Minutes", value: 60 * 60 },
        { label: "Daily", value: 60 * 60 * 24 },
        { label: "Weekly", value: 60 * 60 * 24 * 7 },
      ].filter((g, i) => {
        switch (this.filters.duration.selectedDuration) {
          case 0:
            return [1, 2, 3].includes(i);
          case 1:
            return [4].includes(i);
          case 2:
            return [4, 5].includes(i);
          case 3:
            return [4, 5].includes(i);
          default:
            return [3, 4, 5].includes(i);
        }
      });
      this.filters.duration.selectedGranularity =
        this.filters.duration.granularity[0].value;
      if (this.filters.duration.selectedDuration == 0) {
        this.filters.duration.selectedGranularity = 60 * 30;
      } else if (this.filters.duration.selectedDuration == 1) {
        this.filters.duration.selectedGranularity = 60 * 60 * 24;
      }
      this.setChartGranularity();
    },
    setChartGranularity() {
      // Set granularity options and selection for Custom start and end date
      if (this.chart.selectedDuration === 2) {
        const diffDays = this.getDateDiffInDays(
          this.chart.startDate,
          this.chart.endDate
        );
        if (diffDays > 1) {
          if (diffDays <= 7) {
            this.chart.granularity = [{ label: "Daily", value: 60 * 60 * 24 }];
            this.chart.selectedGranularity = 60 * 60 * 24;
          } else {
            this.chart.granularity = [
              { label: "Daily", value: 60 * 60 * 24 },
              { label: "Weekly", value: 60 * 60 * 24 * 7 },
            ];
            this.chart.selectedGranularity = 60 * 60 * 24 * 7;
          }
        } else {
          this.chart.granularity = [
            { label: "15 Minutes", value: 60 * 15 },
            { label: "30 Minutes", value: 60 * 30 },
            { label: "1 hour", value: 60 * 60 },
          ];
          this.chart.selectedGranularity = 60 * 60;
        }
      } else {
        if (this.chart.selectedDuration == 1) {
          this.chart.granularity = [
            { label: "Daily", value: 60 * 60 * 24 },
            { label: "Weekly", value: 60 * 60 * 24 * 7 },
          ];
          this.chart.selectedGranularity = 60 * 60 * 24 * 7;
        }
      }
    },
    getDateDiffInDays(startDate: string, endDate: string) {
      const diffTime = Math.abs(
        new Date(endDate).getTime() - new Date(startDate).getTime()
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    // convert 24 hour time to 12 hour time format
    timeConvert(time: string) {
      return new Date("1970-01-01T" + time + "Z").toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      });
    },
    filterByRecordIdSetBy() {
      if (this.filters.record_id) {
        this.fetchRecords(1);
      }
    },
    filterByTopMatchingScoreSetBy() {
      if (this.filters.top_matching_score) {
        this.fetchRecords(1);
      }
    },
    onSortChange(options: any) {
      if (options) {
        if (
          options.sortBy &&
          options.sortBy[0] === "top_matching_confidence_score"
        ) {
          if (options.sortDesc && options.sortDesc[0] === true) {
            this.filters.order_by = "top_matching_score_desc";
          } else if (options.sortDesc && options.sortDesc[0] === false) {
            this.filters.order_by = "top_matching_score_asc";
          } else {
            this.filters.order_by = null;
          }
        } else {
          this.filters.order_by = null;
        }
      } else {
        this.filters.order_by = null;
      }
      this.fetchRecords(1);
    },
    filterByRecordId: _.debounce(async function (this: any) {
      this.fetchRecords(1);
    }, 1300),
    filterByMatchingType() {
      this.filters.verified = true;
      if (this.filters.matching_type === "true_positive") {
        this.filters.ai_matched_correctly = true;
        this.filters.ai_predicted = true;
      } else if (this.filters.matching_type === "true_negative") {
        this.filters.ai_matched_correctly = true;
        this.filters.ai_predicted = false;
      } else if (this.filters.matching_type === "false_positive") {
        this.filters.ai_matched_correctly = false;
        this.filters.ai_predicted = true;
      } else if (this.filters.matching_type === "false_negative") {
        this.filters.ai_matched_correctly = false;
        this.filters.ai_predicted = false;
      } else {
        this.filters.verified = null;
        this.filters.ai_matched_correctly = null;
        this.filters.ai_predicted = null;
      }
      this.fetchRecords(1);
    },
    resetFilters() {
      this.pagination.page = 1;
      this.filters.record_id = null;
      this.filters.record_id_match = "equal_to";
      this.filters.top_matching_score = null;
      this.filters.top_matching_score_match = "equal_to";
      this.filters.lpr_id = null;
      this.filters.license_plate_number = null;
      this.filters.verified = null;
      this.filters.ai_predicted = null;
      this.filters.ai_matched_correctly = null;
      this.filters.matching_status = null;
      this.filters.matching_type = null;
      this.fetchRecords(1);
    },
    clearFilter(field: string) {
      if (field == "record") {
        this.filters.record_id = null;
      } else if (field == "score") {
        this.filters.top_matching_score = null;
      } else if (field == "license_plate_number") {
        this.filters.license_plate_number = null;
      } else if (field == "lpr") {
        this.filters.lpr_id = null;
      }
      this.fetchRecords(1);
    },
    refreshPage() {
      // this.chart.startDate = this.todaysDate;
      // this.chart.endDate = this.todaysDate;
      // this.chart.startTime = "00:00";
      // this.chart.endTime = "23:59";
      // this.chart.selectedGranularity = 60 * 60 * 24 * 7;
      // this.chart.selectedDuration = 2;
      // this.pagination.page = 1;
      // this.filters.verified = null;
      // this.filters.ai_predicted = null;
      // this.filters.ai_matched_correctly = null;
      // this.filters.matching_status = null;
      // this.filters.matching_type = null;
      // this.chart.lotId = null;
      // this.chart.cameraId = null;
      // this.setChartDates();
      this.fetchRecords(1);
    },
    convertDate(date: Date) {
      const yyyy = date.getFullYear().toString();
      const mm = (date.getMonth() + 1).toString();
      const dd = date.getDate().toString();

      const mmChars = mm.split("");
      const ddChars = dd.split("");

      return (
        yyyy +
        "-" +
        (mmChars[1] ? mm : "0" + mmChars[0]) +
        "-" +
        (ddChars[1] ? dd : "0" + ddChars[0])
      );
    },
    setTimeIfSameDay() {
      if (this.chart.startDate === this.chart.endDate) {
        if (!(this.filterStartTimestamp || this.filterTimestamp)) {
          this.chart.startTime = "00:00";
          this.chart.endTime = "23:59";
        }
        this.chart.granularity = [
          { label: "15 Minutes", value: 60 * 15 },
          { label: "30 Minutes", value: 60 * 30 },
          { label: "1 hour", value: 60 * 60 },
        ];
      }
    },
    getLargestValue(arr: Array<number>) {
      if (arr == null || (arr && arr.length === 0)) {
        return "";
      }
      let largest = arr[0];
      for (let i = 1; i < arr.length; i++) {
        if (arr[i] > largest) {
          largest = arr[i];
        }
      }
      return largest;
    },
    getLocalDate(d: Date) {
      return dayjs(d).format("YYYY-MM-DD");
    },
    generateRandomColor(num: number) {
      const colors = [
        "Yellow",
        "Blue",
        "Red",
        "Green",
        "Black",
        "Brown",
        "Azure",
        "Ivory",
        "Teal",
        "Silver",
        "Purple",
        "Navy blue",
        "Pea green",
        "Gray",
        "Orange",
        "Maroon",
        "Charcoal",
        "Aquamarine",
        "Coral",
        "Fuchsia",
        "Wheat",
        "Lime",
        "Crimson",
        "Khaki",
        "Hot pink",
        "Magenta",
        "Olden",
        "Plum",
        "Olive",
        "Cyan",
      ];
      return colors[num % 30];
    },
  },
  watch: {
    "chart.startDate"() {
      this.setTimeIfSameDay();
    },
    "chart.endDate"() {
      this.setTimeIfSameDay();
    },
  },
  computed: {
    requestStatuses() {
      return Object.values(VehicleMatchingRequestStatus).map((status) => ({
        text: status,
        value: status,
      }));
    },
    singleDaySelected() {
      if (this.chart.startDate === this.chart.endDate) {
        return true;
      }
      return false;
    },
    todaysDate() {
      return getTodaysDate();
    },
    matchingAccuracyText() {
      if (this.matchingAccuracyData) {
        let total = 0,
          matchedCorrectly = 0,
          matchedIncorrectly = 0,
          falsePositives = 0,
          falseNegatives = 0,
          unverified = 0;
        for (let stat of this.matchingAccuracyData) {
          total += stat.count;
          if (stat.ai_matched_correctly === null) {
            unverified += stat.count;
          } else if (stat.ai_matched_correctly === true) {
            matchedCorrectly += stat.count;
          } else if (stat.ai_matched_correctly === false) {
            matchedIncorrectly += stat.count;
            if (stat.ai_matched_index === null) {
              falseNegatives += stat.count;
            } else {
              falsePositives += stat.count;
            }
          }
        }
        return `${matchedCorrectly} Correct, ${matchedIncorrectly} Incorrect (False Positive: ${falsePositives}/ False Negative: ${falseNegatives}), ${unverified} Unverified`;
      }
      return "";
    },
  },
});
