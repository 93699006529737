var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"align-self":"baseline"}},[_c('v-breadcrumbs',{staticClass:"lot-breadcrumbs pa-0",attrs:{"items":_vm.breadcrumbItems,"divider":"/"}})],1)],1),_c('v-row',{attrs:{"dense":"","align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"page-heading",attrs:{"tabindex":"0","aria-label":"Parking Permits Page"}},[_vm._v(" Parking Permits ")])]),_c('v-col',{attrs:{"cols":"auto","align-self":"baseline"}},[_c('v-dialog',{attrs:{"max-width":"600px","fullscreen":_vm.$vuetify.breakpoint.smAndDown},on:{"click:outside":_vm.closeForm},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","aria-label":"Add new permit type","role":"button","aria-haspopup":false}},'v-btn',attrs,false),on),[_vm._v("Add Permit Type")])]}}]),model:{value:(_vm.showParkingPermitForm),callback:function ($$v) {_vm.showParkingPermitForm=$$v},expression:"showParkingPermitForm"}},[_c('parking-permit-form',{attrs:{"existingPermit":_vm.parkingPermits.selected,"needsInit":_vm.showParkingPermitForm,"lotId":_vm.lotId},on:{"close-form":_vm.closeForm,"refresh-data":_vm.initData}})],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.parkingPermits.headers,"items":_vm.parkingPermits.data,"sort-by":"is_standard","sort-desc":"","hide-default-footer":"","item-class":_vm.getRowClass},on:{"click:row":_vm.openParkingPermitDetails},scopedSlots:_vm._u([{key:"item.icon_symbol",fn:function(ref){
var item = ref.item;
return [(item.icon_symbol)?_c('span',[(item.icon_symbol.startsWith('mdi'))?_c('v-icon',[_vm._v(_vm._s(item.icon_symbol))]):_c('img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":_vm.$getCustomSvgPath(item.icon_symbol)}})],1):_vm._e()]}},{key:"item.is_standard",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.is_standard)?_c('span',[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","tabindex":"0","role":"img","aria-label":((item.name) + " standard permit"),"aria-hidden":"false"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name)+" is a standard permit")])])]}},{key:"item.is_restricted",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.is_restricted)?_c('span',[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","role":"img","tabindex":"0","aria-label":((item.name) + " restricted permit"),"aria-hidden":"false"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name)+" is a restricted permit")])])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatLocalTimestamp")(item.updated_at)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.is_standard)?_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"role":"button","aria-label":((item.name) + " Edit Permit Details")},on:{"click":function($event){$event.stopPropagation();return _vm.openParkingPermitDetails(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit Permit Details")])]),_c('v-tooltip',{attrs:{"top":"","aria-hidden":"true"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"role":"button","aria-label":((item.name) + " Delete Permit")},on:{"click":function($event){$event.stopPropagation();return _vm.openParkingPermitDeleteDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete Permit")])])],1):_vm._e()]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"320"},model:{value:(_vm.showParkingPermitDeleteDialog),callback:function ($$v) {_vm.showParkingPermitDeleteDialog=$$v},expression:"showParkingPermitDeleteDialog"}},[(_vm.parkingPermits.selected)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Delete Parking Permit ID "+_vm._s(_vm.parkingPermits.selected.id))]),_c('v-card-text',[_vm._v("Are you sure you would like to delete this parking permit?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeParkingPermitDeleteDialog}},[_vm._v("Close")]),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.deleteSelectedParkingPermit}},[_vm._v("Delete")])],1)],1):_vm._e()],1),_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }