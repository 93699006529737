import { render, staticRenderFns } from "./ChatBot.vue?vue&type=template&id=44796df1&scoped=true&"
import script from "./ChatBot.vue?vue&type=script&lang=ts&"
export * from "./ChatBot.vue?vue&type=script&lang=ts&"
import style0 from "./ChatBot.vue?vue&type=style&index=0&id=44796df1&scoped=true&lang=css&"
import style1 from "./ChatBot.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44796df1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VIcon,VImg,VSkeletonLoader,VTextarea,VTooltip})
