























































































































































import Vue from "vue";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import {
  ParkingPermit,
  EndUser,
  LicensePlateParkingPermit,
} from "@/api/models";
import EndUserPermitsForm from "@/components/forms/EndUserPermitsForm.vue";
import LicensePlatePermitsForm from "@/components/forms/LicensePlatePermitsForm.vue";
import api from "@/api/api";

export default Vue.extend({
  name: "EndUserPermitGrants",

  components: {
    EndUserPermitsForm,
    LicensePlatePermitsForm,
  },

  data: () => ({
    breadcrumbItems: [
      {
        text: "Home",
        disabled: false,
        to: { name: "Home" },
      },
      {
        text: "Permit Grants",
        disabled: true,
      },
    ] as Record<string, string | any>,

    filters: {
      emailOrLp: null as string | null,
    },

    parkingPermits: {
      data: [] as Array<ParkingPermit>,
      selected: null as ParkingPermit | null,
    },
    endUsers: {
      data: [] as Array<EndUser>,
      selected: null as EndUser | null,
      selectedLicensePlate: null as LicensePlateParkingPermit | null,
      headers: [
        { text: "Email", value: "email" },
        { text: "License Plate", value: "license_plate_number" },
        { text: "Permit", value: "parking_permit_id" },
        // { text: "Last Login", value: "last_login_at" },
      ],
      total: 0,
      page: 1,
      itemsPerPage: 50,
      showDetailsCard: false,
      showLicensePlateForm: false,
    },
    licensePlate: null as string | null,
    multipleLicensePlates: [] as Array<string>,
    lotId: 0,
    parkingPermitGrants: [] as Array<EndUser>,
    licensePlatePermitGrants: [] as Array<LicensePlateParkingPermit>,
    selectedPermitGrant: null as EndUser | LicensePlateParkingPermit | null,

    isLoading: false,
    isLoadingGrantToEmail: false,
  }),
  watch: {
    "endUsers.data": {
      handler() {
        this.enhanceRowsAccessibility();
      },
      immediate: true, // Ensure it runs on the initial render
    },
  },
  computed: {
    ...mapGetters("user", ["isSuperAdmin", "currentUserOrgNameDetails"]),

    singleUserFilteredFromEmail(): EndUser | null {
      if (this.endUsers.data.length == 1) {
        return this.endUsers.data[0]; // Filtered out a single existing user email ID
      }
      return null;
    },

    filterEmailValid() {
      if (this.singleUserFilteredFromEmail) {
        return true;
      }
      if (this.filters.emailOrLp?.match(/.+@.+/)) {
        return true; // New User Email
      }
      return false;
    },

    validateLpOrEmail() {
      if (this.filters.emailOrLp) {
        if (this.filters.emailOrLp.includes("@")) {
          const regex =
            /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
          if (!regex.test(this.filters.emailOrLp)) {
            return ["Invalid Email ID, must be in format: abc@xyz.com"];
          }
        }
        // check if multiple comma seperated license plates
        else if (this.filters.emailOrLp.includes(",")) {
          let licensePlates = this.filters.emailOrLp.split(",");
          for (let i = 0; i < licensePlates.length; i++) {
            licensePlates[i] = licensePlates[i].trim().toUpperCase();
            if (this.allParkingPermitLicensePlates.includes(licensePlates[i])) {
              const lp: string = licensePlates[i];
              return [
                "Permit already granted to the License Plate " +
                  lp +
                  " in list at index " +
                  (i + 1),
              ];
            }
            if (
              !licensePlates[i].match(/^[a-zA-Z0-9]*$/) ||
              licensePlates[i].length < 3 ||
              licensePlates[i].length > 10
            ) {
              return [
                "Invalid License Plate Number in list at index " +
                  (i + 1) +
                  ", must be alphanumeric and 3 to 10 characters",
              ];
            }
          }
        }
        // check if valid license plate
        else {
          if (
            this.allParkingPermitLicensePlates.includes(
              this.filters.emailOrLp.toUpperCase()
            )
          ) {
            return ["Permit already granted to this License Plate"];
          }
          if (
            this.filters.emailOrLp.length > 10 ||
            !this.filters.emailOrLp.match(/^[a-zA-Z0-9]*$/)
          ) {
            return [
              "Invalid License Plate Number, must be alphanumeric and less than 10 characters",
            ];
          }
        }
      }
      return [];
    },

    lpOrEmailHint() {
      if (!this.filters.emailOrLp)
        return "Email ID of the app user, License Plate or License Plates of Vehicle to which the permit must be granted.";
      if (
        this.filters.emailOrLp.length <= 10 &&
        this.filters.emailOrLp.match(/^[a-zA-Z0-9]*$/) &&
        !this.filters.emailOrLp.includes("@")
      ) {
        return "License Plate of Vehicle to which the permit must be granted.";
      } else if (this.filters.emailOrLp.match(/.+@.+/)) {
        return "Email ID of the app user to which the permit must be granted.";
      }
      return "Email ID of the app user or License Plate of Vehicle to which the permit must be granted.";
    },

    allParkingPermitLicensePlates(): Array<string> {
      let licensePlates: Array<string> = [];
      if (
        this.licensePlatePermitGrants != null &&
        this.licensePlatePermitGrants.length > 0
      ) {
        for (let lpPermit of this.licensePlatePermitGrants) {
          if (lpPermit.license_plate_number != null) {
            licensePlates.push(lpPermit.license_plate_number);
          }
          if (lpPermit.license_plate_numbers != null) {
            licensePlates.push(...lpPermit.license_plate_numbers);
          }
        }
      }
      return licensePlates;
    },

    allParkingPermitGrantData(): Array<EndUser | LicensePlateParkingPermit> {
      // remove duplicates from parkingPermitGrants with same license_plate_number
      const licensePlatePermitGrants = this.licensePlatePermitGrants.filter(
        (lpPermit, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              lpPermit.license_plate_number != null &&
              t.license_plate_number === lpPermit.license_plate_number
          )
      );
      const multipleLicensePlatePermitGrants =
        this.licensePlatePermitGrants.filter(
          (lpPermit) => lpPermit.license_plate_numbers != null
        );
      let parkingPermitGrants = [
        ...this.parkingPermitGrants,
        ...licensePlatePermitGrants,
        ...multipleLicensePlatePermitGrants,
      ];
      if (this.filters.emailOrLp) {
        // check if email or license plate number matches the filter
        parkingPermitGrants = parkingPermitGrants.filter((permit) => {
          if ("email" in permit && this.filters.emailOrLp) {
            return permit.email
              .toLowerCase()
              .includes(this.filters.emailOrLp.toLowerCase());
          } else if (
            "license_plate_number" in permit &&
            permit.license_plate_number &&
            this.filters.emailOrLp
          ) {
            return permit.license_plate_number
              .toLowerCase()
              .includes(this.filters.emailOrLp.toLowerCase());
          }
        });
      }
      return parkingPermitGrants;
    },
  },

  async mounted() {
    this.lotId = Number(this.$route.params.lotId);

    this.isLoading = true;
    this.getLotData(this.lotId);
    await this.getParkingPermitTypes();
    await this.getEndUsersData();
    this.isLoading = false;
  },

  methods: {
    ...mapActions("data", ["initCurrentParkingLotData"]),
    getRowClass() {
      return {
        "focusable-row": true,
      };
    },
    enhanceRowsAccessibility() {
      this.$nextTick(() => {
        const rows = document.querySelectorAll(".focusable-row");
        rows.forEach((row, index) => {
          row.setAttribute("tabindex", "0");
          row.addEventListener("keydown", (event) => {
            if (event instanceof KeyboardEvent) {
              const item = this.endUsers.data[index];
              if (event.key === "Enter" || event.key === " ") {
                event.preventDefault();
                this.openFormToGrantPermit(item);
              }
            }
          });
        });
      });
    },
    async getLotData(lotId: number | null) {
      if (lotId) {
        let parkingLotData = await api.getParkingLot(lotId);
        this.initCurrentParkingLotData(parkingLotData);

        if (parkingLotData) {
          this.breadcrumbItems[0].text = parkingLotData.name;
          this.breadcrumbItems[0].to = { name: "LotDashboard" };
        }
      }
    },

    async getParkingPermitTypes() {
      try {
        const parkingPermitsData = await api.getAllParkingPermits(
          this.lotId,
          true
        );
        if (parkingPermitsData) {
          this.parkingPermits.data = parkingPermitsData;
        }
      } catch (e) {
        console.log(e);
        this.$dialog.message.error(
          "Error, failed to load parking permits. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    async getEndUsersData() {
      try {
        this.parkingPermitGrants = [];
        this.licensePlatePermitGrants = [];
        this.getAllLicensePlatePermitGrants();
        const endUsersData = await api.getAllEndUsersGrantedPermitInLotId(
          this.lotId,
          this.filters.emailOrLp,
          this.endUsers.page,
          this.endUsers.itemsPerPage == -1 ? 100 : this.endUsers.itemsPerPage
        );
        if (endUsersData) {
          this.endUsers.data = endUsersData.items;
          this.parkingPermitGrants.push(...endUsersData.items);
          this.endUsers.total = endUsersData.total;
        }
      } catch (e) {
        console.log(e);
        this.$dialog.message.error(
          "Error, failed to load users data. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    async getAllLicensePlatePermitGrants() {
      try {
        const licensePlatePermitsData = await api.getAllLpPermitGrantDetails(
          this.lotId
        );
        if (licensePlatePermitsData) {
          this.licensePlatePermitGrants.push(...licensePlatePermitsData);
        }
      } catch (e) {
        console.log(e);
        this.$dialog.message.error(
          "Error, failed to load license plate permits data. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    onEmailFilterKeyDown: _.debounce(async function (this: any) {
      this.getEndUsersData();
    }, 600),

    onEmailFilterClear() {
      this.filters.emailOrLp = null;
      this.getEndUsersData();
    },

    async onEndUsersTablePaginationChanged(options: any) {
      console.log("End Users Pagination options changed", options);
      this.endUsers.page = options.page;
      this.endUsers.itemsPerPage = options.itemsPerPage;
      this.getEndUsersData();
    },

    openFormToGrantPermit(item: any) {
      if ("email" in item) {
        console.log("Opening form to grant permit to email");
        this.endUsers.selected = item;
        this.endUsers.showDetailsCard = true;
      } else if (
        "license_plate_numbers" in item &&
        item.license_plate_numbers != null
      ) {
        console.log("Opening form to grant permit to multiple license plates");
        this.multipleLicensePlates = item.license_plate_numbers;
        this.endUsers.selectedLicensePlate = item;
        this.endUsers.showLicensePlateForm = true;
      } else {
        console.log("Opening form to grant permit to single license plate");
        this.licensePlate = item.license_plate_number;
        this.endUsers.selectedLicensePlate = item;
        this.endUsers.showLicensePlateForm = true;
      }
    },

    openUserDetailsForm(endUser: EndUser) {
      this.endUsers.showDetailsCard = true;
      this.endUsers.selected = endUser;
    },

    closeUserDetailsForm() {
      this.endUsers.showDetailsCard = false;
      this.endUsers.selected = null;
    },

    openLicenseDetailsForm(license_permit: LicensePlateParkingPermit | null) {
      this.endUsers.showLicensePlateForm = true;
      this.endUsers.selectedLicensePlate = license_permit;
    },

    closeLicenseDetailsForm() {
      this.filters.emailOrLp = null;
      this.licensePlate = null;
      this.multipleLicensePlates = [];
      this.endUsers.showLicensePlateForm = false;
      this.endUsers.selectedLicensePlate = null;
    },

    async grantPermitToEmailOrLp() {
      if (!this.filters.emailOrLp) return;
      if (this.filters.emailOrLp.includes("@")) {
        // Email ID
        if (this.singleUserFilteredFromEmail) {
          this.endUsers.selected = this.singleUserFilteredFromEmail;
          this.endUsers.showDetailsCard = true;
        } else if (this.filters.emailOrLp) {
          // Create a new end user account for given email, then show
          // form to assign new ID.
          this.isLoadingGrantToEmail = true;
          const newEndUser = await api.createOrGetEndUserFromEmail(
            this.lotId,
            this.filters.emailOrLp
          );
          this.endUsers.selected = newEndUser;
          this.endUsers.showDetailsCard = true;
          this.isLoadingGrantToEmail = false;
        } else {
          this.$dialog.message.error("Error, Please enter a valid email.", {
            position: "top-right",
            timeout: 6000,
          });
        }
      } else if (this.filters.emailOrLp.includes(",")) {
        // Multiple License Plates
        console.log("Multiple License Plates");
        let licensePlates = this.filters.emailOrLp.split(",");
        licensePlates = licensePlates.map((lp) => lp.trim().toUpperCase());
        this.multipleLicensePlates = licensePlates;
        this.openLicenseDetailsForm(null);
      } else {
        // Single License Plate
        this.licensePlate = this.filters.emailOrLp.trim().toUpperCase();
        this.openLicenseDetailsForm(null);
      }
    },

    getPermitName(permitId: number) {
      const permit = this.parkingPermits.data.find((p) => p.id === permitId);
      return permit ? permit.name : "";
    },
  },
});
