import { MaintenanceWindow } from "./MaintenanceWindowHistory";

export interface OrganizationUserSession {
  email: string;
  token: string;
  isLoginPending: boolean;
  currentUserData: OrganizationUserWithExtraData | null;
  dismissedUpcomingMaintenanceStartsAt: string | null;
  isMaintenanceOngoing: boolean;
  upcomingMaintenanceWindow: MaintenanceWindow | null;
  superAdminViewSwitched: boolean;
}

export interface OrganizationUserLoginPayload {
  username: string;
  password: string;
}

export interface OrganizationUserLoginResponseToken {
  token_type: string;
  access_token: string;
}

///Auth Methods (SAML | Password)
// Define types for both possible responses
export type AuthMethod = "saml" | "password";

export interface SAMLAuthResponse {
  auth_method: "saml";
  organization_name: string;
  force_saml: boolean;
  sso_url?: string;
}

export interface PasswordAuthResponse {
  auth_method: "password";
}

export type AuthCheckResponse = SAMLAuthResponse | PasswordAuthResponse;

export enum OrganizationUserType {
  superadmin = "superadmin",
  admin = "admin",
  operator = "operator",
  technician = "technician",
  developer = "developer",
}

export enum OrganizationCustomerUserType {
  admin = "admin",
  operator = "operator",
  developer = "developer",
}

export interface OrganizationUserBase {
  name: string;
  email: string;
  user_type: OrganizationUserType;
  address?: string;
  receive_emails?: boolean;
  receive_alert_types?: Array<number>;
  is_option_settings_enabled?: boolean;
}

export interface CurrentOrganizationName {
  name: string;
  id: number;
  is_parking_history_visible: boolean;
}

export interface OrganizationUser extends OrganizationUserBase {
  id: number;
}

export interface OrganizationUserWithExtraData extends OrganizationUser {
  organization: CurrentOrganizationName;
  upcoming_maintenance_window: MaintenanceWindow | null;
}

export interface OrganizationUserUpdate extends OrganizationUserBase {
  password?: string;
  old_password?: string;
}

export interface OrganizationUserCreate extends OrganizationUserUpdate {
  organization_id: number;
}
