








































































































































































































import Vue from "vue";
import Konva from "konva";

import api from "@/api/api";
import {
  CameraMapDetails,
  InferenceRequestQueueResponse,
  InferenceRequestStatus,
} from "@/api/models";

import {
  TOOLS,
  BBOX_CATEGORY,
  BboxBasic,
  Bbox,
  POLYGON_CATEGORY,
  PolygonBasic,
  Polygon,
  LineBasic,
  Line,
  SpotBbox,
} from "@/libs/commonCameraMapEditorTypes";
import ImageAnnotator from "@/components/ImageAnnotator.vue";
import { update } from "lodash";
interface CameraMapErrors {
  invalidAnnotations: Array<Record<string, any>>;
  errorMsgs: Array<string>;
}

export default Vue.extend({
  name: "CameraMapDetails",

  components: {
    ImageAnnotator,
  },

  props: {
    cameraId: {
      type: Number,
      required: false,
    },
    parkingLotId: {
      type: Number,
      required: false,
    },
    needsInit: {
      type: Boolean,
      required: true,
    },
    showAnnotations: {
      type: Boolean,
      required: false,
      default: true,
    },
    imageURL: {
      required: false,
      type: String,
      default: null,
    },
    isViewerMode: {
      required: false,
      default: false,
    },
    isEditMode: {
      required: false,
      default: true,
    },
    stageWidth: {
      required: false,
      default: 998,
    },
    stageHeight: {
      required: false,
      default: 500,
    },
    annoSelectedOnDigimap: {
      required: false,
      default: null,
    },
    zoneSelectedOnDigimap: {
      required: false,
      default: null,
    },
    specialAreaSelectedOnDigimap: {
      required: false,
      default: null,
    },
    showProposed: {
      required: false,
      default: true,
    },
    isQrTamperDetectionEnabled: {
      required: false,
      default: false,
    },
  },

  data: () => ({
    loadImageUrl: null as string | null,
    cameraMapImage: {
      url: null as string | null,
      blobPath: null as string | null,
      height: null as number | null,
      width: null as number | null,
    },
    spotBboxes: [] as Array<BboxBasic>,
    roiPolygons: [] as Array<PolygonBasic>,
    counterZoneLines: [] as Array<LineBasic>,
    cameraMapData: null as CameraMapDetails | null,
    importImage: {
      showDialog: false,
      uploadedFile: null as File | null,
    },
    importBboxes: true,
    pendingCameraMapUpdateAlertId: null as number | null,
    waitingForCameraToBeDisabled: false,
    qrSign: {
      showDialog: false,
      name: "" as string | null,
      url: "" as string | null,
    },
    showPopConfirmDelete: {
      showDialog: false,
      confirmationText: "",
    },
    selectedBboxId: "" as string | null,
    selectedBboxName: "" as string | null,
  }),

  computed: {
    /**
     * Check if flexible camera mapping feature (devops task 1400) should be enabled
     * in current parking lot only if its lot ID is present in frontend env var.
     */
    isFeatureFlexibleCameraMappingEnabled(): boolean {
      return process.env.VUE_APP_FEATURE_ENABLE_FLEXIBLE_CAMERA_MAPPING_FOR_LOT_IDS?.split(
        ","
      ).includes(String(this.parkingLotId));
    },
  },

  async mounted() {
    if (this.isViewerMode && this.imageURL) {
      this.loadImageUrl = this.imageURL;
    }
    if (this.needsInit == true) {
      await this.initCameraMapData();
    }
  },

  methods: {
    /**
     * Fetch camera map from backend and load it into the camera map editor.
     */
    async initCameraMapData() {
      if (!this.parkingLotId || !this.cameraId) {
        this.$dialog.message.error(
          "Error, camera map can not be displayed for invalid camera ID or lot ID.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        return;
      }
      let cameraMapData = await api.getCameraMapDetails(
        this.parkingLotId,
        this.cameraId
      );
      if (cameraMapData) {
        this.cameraMapData = cameraMapData;
        if (cameraMapData.map_frame_path_url == null) {
          // If this camera does not have a camera map, then import current frame
          this.$dialog.message.info(
            "Automatically importing current frame for camera without camera map.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
          this.runImportImageWithInference("current");
        } else {
          // Load the existing camera map
          if (
            this.showProposed &&
            cameraMapData.map_updated_frame_path &&
            cameraMapData.map_updated_frame_path.length > 0 &&
            cameraMapData.map_updated_frame_path_url
          ) {
            this.cameraMapImage.url = cameraMapData.map_updated_frame_path_url;
          } else {
            this.cameraMapImage.url = cameraMapData.map_frame_path_url;
          }
          if (!(this.isViewerMode && this.imageURL != null)) {
            this.loadImageUrl = this.cameraMapImage.url;
          }
          this.cameraMapImage.blobPath = cameraMapData.map_frame_path;
          this.cameraMapImage.width = cameraMapData.frame_width;
          this.cameraMapImage.height = cameraMapData.frame_height;
        }

        // Clear old bboxes and polys
        this.spotBboxes.splice(0, this.spotBboxes.length);
        this.roiPolygons.splice(0, this.roiPolygons.length);
        this.counterZoneLines.splice(0, this.counterZoneLines.length);

        // Load bboxes from parking spots info
        if (this.cameraMapData?.parking_spots) {
          let spotIdColors = new Map<number, string>(); // To use the same color in spotID of both existing and proposed bbox
          for (const spot of this.cameraMapData.parking_spots) {
            let bbox = spot.meta?.camera_frame?.bbox;
            let poly = spot.meta?.camera_frame?.poly;
            // do not draw proposed bboxes if not in edit mode
            let proposedBbox = this.isEditMode
              ? spot.updated_meta?.camera_frame?.bbox
              : null;
            if (bbox) {
              let [x, y, width, height] = bbox;
              if (!spotIdColors.has(spot.id)) {
                spotIdColors.set(spot.id, Konva.Util.getRandomColor());
              }
              this.spotBboxes.push({
                spotId: spot.id,
                spotName: spot.name,
                laneId: spot.parking_lane_id,
                x,
                y,
                width,
                height,
                category: BBOX_CATEGORY.spot,
                // fill: spotIdColors.get(spot.id),
              });
            } else if (
              this.isFeatureFlexibleCameraMappingEnabled &&
              poly &&
              poly.length > 0
            ) {
              if (!spotIdColors.has(spot.id)) {
                spotIdColors.set(spot.id, Konva.Util.getRandomColor());
              }
              this.roiPolygons.push({
                points: poly[0],
                annoId: spot.id,
                annoName: spot.name,
                category: POLYGON_CATEGORY.spot,
              });
            }
            if (proposedBbox) {
              let [x, y, width, height] = proposedBbox;
              this.spotBboxes.push({
                spotId: spot.id,
                spotName: spot.name,
                laneId: spot.parking_lane_id,
                x,
                y,
                width,
                height,
                // fill: spotIdColors.get(spot.id),
                extraText: "(proposed)",
                category: BBOX_CATEGORY.spot,
              });
            }
          }
        }

        // Load ev charger bboxes
        if (this.cameraMapData?.parking_spots_ev_chargers) {
          for (const evCharger of this.cameraMapData
            .parking_spots_ev_chargers) {
            let bbox = evCharger.ev_charger_anno?.camera_frame?.bbox;
            if (bbox) {
              let spotName =
                this.cameraMapData.parking_spots.find(
                  (s) => s.id === evCharger.parking_spot_id
                )?.name || null;
              let [x, y, width, height] = bbox;
              this.spotBboxes.push({
                spotId: evCharger.parking_spot_id,
                spotName,
                laneId: null,
                x,
                y,
                width,
                height,
                category: BBOX_CATEGORY.ev_charger,
                // fill: spotIdColors.get(spot.id),
              });
            }
          }
        }

        // Load signage objects
        if (this.cameraMapData?.signage_objects) {
          for (const signageObject of this.cameraMapData.signage_objects) {
            let bbox = signageObject.annotation?.camera_frame?.bbox;
            if (bbox) {
              let [x, y, width, height] = bbox;
              this.spotBboxes.push({
                signageId: signageObject.id,
                spotId: null,
                spotName: null,
                laneId: null,
                x,
                y,
                width,
                height,
                name: signageObject.name,
                text: signageObject.reference_content,
                category: BBOX_CATEGORY.qr_code,
              });
            }
          }
        }

        if (this.cameraMapData?.special_areas.length > 0) {
          for (const area of this.cameraMapData.special_areas) {
            if (area && area.count_vehicles_only_in_roi) {
              this.roiPolygons.push({
                points: area.count_vehicles_only_in_roi.poly[0],
                annoId: area.id,
                annoName: null,
                category: POLYGON_CATEGORY.special_area,
              });
            }
          }
        }

        // Load camera roi and untracked zone id
        let annoIdToDisplay = null;
        let categoryToDisplay = null;
        if (this.cameraMapData.untracked_zone_id) {
          annoIdToDisplay = this.cameraMapData.untracked_zone_id;
          categoryToDisplay = POLYGON_CATEGORY.zone;
        } else {
          annoIdToDisplay = this.cameraId;
          categoryToDisplay = POLYGON_CATEGORY.camera;
        }
        if (this.cameraMapData?.count_vehicles_only_in_roi?.poly) {
          for (let polyShape of this.cameraMapData.count_vehicles_only_in_roi
            .poly) {
            this.roiPolygons.push({
              points: polyShape,
              annoId: annoIdToDisplay,
              annoName: null,
              category: categoryToDisplay,
            });
          }
        }

        // Load counter zone lines
        if (this.cameraMapData?.count_vehicles_crossing_line_points?.line) {
          for (let linePoints of this.cameraMapData
            .count_vehicles_crossing_line_points.line) {
            this.counterZoneLines.push({
              annoId: cameraMapData.counting_zone_id,
              annoName: null,
              points: linePoints,
              category: "zone",
            });
          }
        }

        // Load lane roi
        if (this.cameraMapData?.parking_lanes_cameras) {
          for (let lane of this.cameraMapData.parking_lanes_cameras) {
            if (lane && lane.double_parking_lane_obstruction_roi) {
              this.roiPolygons.push({
                points: lane.double_parking_lane_obstruction_roi.poly[0],
                annoId: lane.parking_lane_id,
                annoName: null,
                category: POLYGON_CATEGORY.lane,
              });
            }
          }
        }

        this.pendingCameraMapUpdateAlertId = cameraMapData.map_updated_alert_id;
      } else {
        this.$dialog.message.error(
          "Error, failed to load Camera Map. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    async showPendingUpdateAlert() {
      if (this.pendingCameraMapUpdateAlertId) {
        this.$router.push({
          name: "LotAlerts",
          query: { alert_id: String(this.pendingCameraMapUpdateAlertId) },
        });
      } else {
        this.$dialog.message.error(
          "This camera does not have any pending proposal.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    checkIsCameraMapInvalid(annotations: Record<string, any>): CameraMapErrors {
      const invalidAnnotations = [];
      const errorMsgs = [];
      // Check spot bboxes
      let seenSpots: Record<number, boolean> = {};
      let seenEvChargers: Record<number, boolean> = {};
      for (const bbox of annotations.bboxes) {
        if (bbox.spotId == null && bbox.category !== BBOX_CATEGORY.qr_code) {
          invalidAnnotations.push(bbox);
          errorMsgs.push(`Bbox (with red border) is not assigned to any spot.`);
        }
        if (bbox.category === BBOX_CATEGORY.spot) {
          if (seenSpots[bbox.spotId]) {
            invalidAnnotations.push(bbox);
            errorMsgs.push(
              `Spot ${bbox.spotId} is assigned to multiple bboxes.`
            );
          }
          seenSpots[bbox.spotId] = true;
        } else if (bbox.category === BBOX_CATEGORY.ev_charger) {
          if (seenEvChargers[bbox.spotId]) {
            invalidAnnotations.push(bbox);
            errorMsgs.push(
              `EV Charger ${bbox.spotId} is assigned to multiple bboxes.`
            );
          }
          seenEvChargers[bbox.spotId] = true;
        }
      }
      // Check polygons
      for (const poly of annotations.polygons) {
        if (poly.points == null) {
          invalidAnnotations.push(poly);
          errorMsgs.push(`Polygon does not have any points.`);
        }
      }
      // Check lines
      if (
        annotations.lines.length >= 1 &&
        (annotations.polygons.length > 0 || annotations.bboxes.length > 0)
      ) {
        invalidAnnotations.push(annotations.lines[0]);
        errorMsgs.push(
          `Counter zone line can not be drawn along with other bbox or polygon annotations. <br> Please remove other annotations and try again.`
        );
      }
      for (const line of annotations.lines) {
        if (line.annoId == null) {
          invalidAnnotations.push(line);
          errorMsgs.push(`Counter zone line is not assigned to any zone.`);
        }
      }
      return { invalidAnnotations, errorMsgs };
    },

    /**
     * Save all annotations drawn on the camera map editor by extracting, rois, bboxes
     * and saving it into the DB via save camera map api.
     *
     * Note, save may be aborted if validation errors are found.
     */
    async saveCameraMap(
      annotations: Record<string, any>,
      deletedBboxes: number[]
    ) {
      console.log(annotations);
      let { invalidAnnotations, errorMsgs } =
        this.checkIsCameraMapInvalid(annotations);
      console.log("Invalid annotations", invalidAnnotations, errorMsgs);
      if (invalidAnnotations.length > 0) {
        let errorsList = errorMsgs.map((msg) => `<li>${msg}</li>`).join("<br>");
        this.$dialog.message.error(
          `Unable to save Camera Map with ${invalidAnnotations.length} invalid annotations.<br>` +
            "Please rectify the following errors and try again:<br><br> <ul>" +
            errorsList +
            "</ul>",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        return false;
      }

      // Get spots drawn using bboxes
      let spots: SpotBbox[] = [];
      let evChargers = [];
      let signageObjects = [];

      for (const bbox of annotations.bboxes) {
        if (bbox.category == BBOX_CATEGORY.spot) {
          spots.push({
            spotId: bbox.spotId,
            x: bbox.x,
            y: bbox.y,
            width: bbox.width,
            height: bbox.height,
            points: null,
          });
        } else if (bbox.category == BBOX_CATEGORY.ev_charger) {
          evChargers.push({
            spotId: bbox.spotId,
            x: bbox.x,
            y: bbox.y,
            width: bbox.width,
            height: bbox.height,
          });
        } else if (bbox.category == BBOX_CATEGORY.qr_code) {
          signageObjects.push({
            signageId: bbox.signageId,
            x: bbox.x,
            y: bbox.y,
            width: bbox.width,
            height: bbox.height,
            name: bbox.name,
            text: bbox.text,
          });
        }
      }
      // Extract spots drawn using polygons
      if (
        this.isFeatureFlexibleCameraMappingEnabled &&
        annotations.polygons.length > 0
      ) {
        for (const poly of annotations.polygons) {
          if (poly.points == null || poly.points.length == 0) {
            continue; // Skip this poly since there is no points in this poly
          }
          if (poly.category !== "spot") {
            continue;
          }
          spots.push({
            spotId: poly.annoId,
            x: null,
            y: null,
            width: null,
            height: null,
            points: poly.points,
          });
        }
      }

      // Extract roi drawn using polygons
      let cameraRoiPolyPoints = null;
      let untrackedZoneId = null;
      let specialAreas = [];
      let parkingLanes = [];
      if (annotations.polygons.length > 0) {
        for (const poly of annotations.polygons) {
          if (poly.points == null || poly.points.length == 0) {
            continue; // Skip this poly since there is no points in this poly
          }

          if (poly.category == "spot") {
            continue; // Skip spots drawn using polygons
          } else if (poly.category === "zone" || poly.category === "camera") {
            if (cameraRoiPolyPoints == null) {
              cameraRoiPolyPoints = { poly: [poly.points] };
            } else {
              cameraRoiPolyPoints.poly.push(poly.points);
            }
            if (poly.category == "zone") {
              untrackedZoneId = poly.annoId;
            }
          } else if (poly.category === "special_area") {
            specialAreas.push({
              areaId: poly.annoId,
              count_vehicles_only_in_roi: { poly: [poly.points] },
            });
          } else if (poly.category === "lane") {
            parkingLanes.push({
              laneId: poly.annoId,
              double_parking_lane_obstruction_roi: { poly: [poly.points] },
            });
          }
        }
      }

      // Extract counter zone lines drawn using line tool
      let counterZoneId = null;
      let zoneLinePoints = null;
      for (const line of annotations.lines) {
        if (line.category === "zone") {
          counterZoneId = line.annoId || counterZoneId;
          if (zoneLinePoints == null) {
            zoneLinePoints = { line: [line.points] };
          } else {
            zoneLinePoints.line.push(line.points);
          }
        }
      }

      let savedCameraMap = null;
      try {
        this.waitingForCameraToBeDisabled = true;
        savedCameraMap = await api.updateCameraMapDetails(
          this.parkingLotId,
          this.cameraId,
          {
            parking_spots: spots,
            parking_spots_ev_chargers: evChargers,
            special_areas: specialAreas,
            parking_lanes_cameras: parkingLanes,
            frame_width: this.cameraMapImage.width,
            frame_height: this.cameraMapImage.height,
            map_frame_path: this.cameraMapImage.blobPath,
            count_vehicles_only_in_roi: cameraRoiPolyPoints,
            untracked_zone_id: untrackedZoneId,
            counter_zone_id: counterZoneId,
            count_vehicles_crossing_line_points: zoneLinePoints,
            signage_objects: signageObjects,
          }
        );

        this.updateSpotIcons(spots, deletedBboxes);
        this.waitingForCameraToBeDisabled = false;
        console.log(savedCameraMap);
        this.$dialog.message.info("Saved camera map successfully", {
          position: "top-right",
          timeout: 3000,
        });
      } catch (e: any) {
        this.waitingForCameraToBeDisabled = false;
        this.$dialog.message.error(`${e.response?.data?.detail}`, {
          position: "top-right",
          timeout: 3000,
        });
        return false;
      }
    },
    updateSpotIcons(spots: SpotBbox[], deletedBboxes: number[]) {
      this.$emit("update-spot-icons", spots, deletedBboxes);
    },
    async runImportImageWithInference(uploadMethod: string) {
      let inferenceResponse: InferenceRequestQueueResponse | null = null;
      switch (uploadMethod) {
        case "current": {
          inferenceResponse = await api.importCameraMapImageFromLatestFrame(
            this.parkingLotId,
            this.cameraId
          );
          break;
        }
        case "highestVehicle": {
          inferenceResponse =
            await api.importCameraMapImageFromHighestVehicleCount(
              this.parkingLotId,
              this.cameraId
            );
          break;
        }
        case "upload": {
          if (this.importImage.uploadedFile) {
            inferenceResponse = await api.importCameraMapImageFromUploadedImage(
              this.parkingLotId,
              this.cameraId,
              this.importImage.uploadedFile
            );
          }
          break;
        }
      }

      if (inferenceResponse != null) {
        if (
          inferenceResponse.inference_status == InferenceRequestStatus.completed
        ) {
          this.$dialog.message.info("Imported image successfully.", {
            position: "top-right",
            timeout: 3000,
          });
        } else {
          this.$dialog.message.error(
            "Imported image, but could not pregenerate bboxes.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
        this.cameraMapImage.url = inferenceResponse.image_path_url;
        this.cameraMapImage.blobPath = inferenceResponse.image_path;
        if (!(this.isViewerMode && this.imageURL != null)) {
          this.loadImageUrl = this.cameraMapImage.url;
        }
        if (inferenceResponse.image_path_url) {
          const imageObj = new Image();
          imageObj.src = inferenceResponse.image_path_url;
          imageObj.onload = () => {
            // scale or dwonsacale image if new imported image resolution is different from earlier image
            const previous_image_width = this.cameraMapImage.width;
            const previous_image_height = this.cameraMapImage.height;

            this.cameraMapImage.width = imageObj.width;
            this.cameraMapImage.height = imageObj.height;

            const new_image_width = this.cameraMapImage.width;
            const new_image_height = this.cameraMapImage.height;

            if (
              previous_image_width &&
              previous_image_height &&
              (previous_image_width != new_image_width ||
                previous_image_height != new_image_height)
            ) {
              this.spotBboxes = [...this.spotBboxes].map((bbox) => {
                bbox.x = bbox.x * (new_image_width / previous_image_width);
                bbox.y = bbox.y * (new_image_height / previous_image_height);
                bbox.width =
                  bbox.width * (new_image_width / previous_image_width);
                bbox.height =
                  bbox.height * (new_image_height / previous_image_height);
                return bbox;
              });

              this.roiPolygons = [...this.roiPolygons].map((poly) => {
                poly.points = [...poly.points].map((point, index) => {
                  if (index % 2 == 0) {
                    return point * (new_image_width / previous_image_width);
                  } else {
                    return point * (new_image_height / previous_image_height);
                  }
                });
                return poly;
              });
            }

            // Load [0-1.0] scaled detections into spots array
            if (this.importBboxes && inferenceResponse) {
              for (let det of inferenceResponse.detections) {
                if (det.class_name === "space-occupied") {
                  // Decode yolo format into pixel coords for each detection
                  // [xcenter, ycenter, width, height]
                  let [x, y, width, height] = det.bbox;
                  this.spotBboxes.push({
                    spotId: null,
                    spotName: null,
                    laneId: null,
                    x: (x - width / 2) * this.cameraMapImage.width,
                    y: (y - height / 2) * this.cameraMapImage.height,
                    width: width * this.cameraMapImage.width,
                    height: height * this.cameraMapImage.height,
                    category: BBOX_CATEGORY.spot,
                  });
                }
              }
            }
            (this.$refs.imageannotator as any).init(); // Force redraw after rescaling
          };
        }
      } else {
        this.$dialog.message.error(
          "Error, unable to load image right now. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },
    showQrDetailsPopup(selectedBboxId: string) {
      this.selectedBboxId = selectedBboxId;
      this.qrSign.showDialog = true;
    },
    showConfirmationPopup(selectedBboxId: string, selectedBboxName: string) {
      console.log("selectedBboxName", selectedBboxName);
      this.selectedBboxId = selectedBboxId;
      this.selectedBboxName = selectedBboxName;
      this.showPopConfirmDelete.showDialog = true;
    },
    onAdd() {
      if (this.$refs.imageAnnotator as any) {
        const values: any = {
          name: this.qrSign.name,
          text: this.qrSign.url,
        };
        (this.$refs.imageAnnotator as any).handleUpdateQRBbox(
          this.selectedBboxId,
          values
        );
      }

      this.qrSign.showDialog = false;

      // Reset values
      this.qrSign.name = "";
      this.qrSign.url = "";
    },
    onCancel() {
      if (this.$refs.imageAnnotator as any) {
        (this.$refs.imageAnnotator as any).selectedBboxId = this.selectedBboxId;
        (this.$refs.imageAnnotator as any).handleDelete();
      }

      this.qrSign.showDialog = false;

      // Reset values
      this.qrSign.name = "";
      this.qrSign.url = "";
    },
    onCancelPopConfirm() {
      this.showPopConfirmDelete.showDialog = false;
      this.showPopConfirmDelete.confirmationText = "";
    },
    onDelete() {
      if (this.$refs.imageAnnotator as any) {
        (this.$refs.imageAnnotator as any).deleteSelectedAnno();
      }

      this.showPopConfirmDelete.showDialog = false;
      this.showPopConfirmDelete.confirmationText = "";
    },
  },

  watch: {
    needsInit(show) {
      if (show) {
        this.initCameraMapData();
      }
    },

    annoSelectedOnDigimap(anno) {
      (this.$refs.imageAnnotator as any).setSelectedAnnoMapping(
        "spot",
        anno.id,
        anno.name,
        anno
      );
    },

    zoneSelectedOnDigimap(anno) {
      if (anno.category === "zone") {
        (this.$refs.imageAnnotator as any).setSelectedAnnoMapping(
          "zone",
          anno.id,
          anno.name,
          anno
        );
      }
    },

    specialAreaSelectedOnDigimap(anno) {
      if (anno.category === "special_area") {
        (this.$refs.imageAnnotator as any).setSelectedAnnoMapping(
          "special_area",
          anno.id,
          anno.name,
          anno
        );
      }
    },

    /**
     * Reload the image whenever the URL change
     */
    imageURL(newImageURL) {
      if (this.isViewerMode && this.imageURL) {
        this.loadImageUrl = newImageURL;
      }
    },
  },
});
