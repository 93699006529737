export enum TOOLS {
  SELECT = "SELECT",
  BBOX = "BBOX",
  POLYGON = "POLYGON",
  LINE = "LINE",
  EV_BBOX = "EV_BBOX",
  QR_CODE = "QR_CODE",
}

export enum BBOX_CATEGORY {
  spot = "spot",
  ev_charger = "ev_charger",
  qr_code = "qr",
}

export interface BboxBasic {
  spotId: number | null;
  spotName: string | null;
  laneId: number | null;
  x: number;
  y: number;
  width: number;
  height: number;
  category: BBOX_CATEGORY;
  extraText?: string;
  signageId?: number | null;
  name?: string;
  text?: string;
}

export interface Bbox extends BboxBasic {
  id: string;
  fill: string;
  opacity: number;
  visible: boolean;
}

export interface SpotBbox {
  spotId: number; // or string, depending on the actual type
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  points?: any; // Adjust type if `points` has a specific structure
}

export enum POLYGON_CATEGORY {
  camera = "camera", // camera map roi
  zone = "zone", // untracked zone roi
  special_area = "special_area", // special area roi
  lane = "lane", // double parking lane roi
  spot = "spot", // spot roi poly when flexible camera map feature flag is enabled for specific lots
}

export interface PolygonBasic {
  annoId: number | null;
  annoName: string | null;
  points: Array<number>;
  category: POLYGON_CATEGORY;
}

export interface Polygon extends PolygonBasic {
  id: string;
  fill: string;
  opacity: number;
  closed: boolean;
  visible: boolean;
}

export interface LineBasic {
  annoId: number | null;
  annoName: string | null;
  points: Array<number>;
  category: string;
}

export interface Line extends LineBasic {
  id: string;
  stroke: string;
  opacity: number;
  visible: boolean;
}
