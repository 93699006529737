<template>
  <div class="center-container">Processing...</div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapMutations } from "vuex";

export default Vue.extend({
  async mounted() {
    try {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("token");
      if (token) {
        await this.loginSaml(token);
        this.redirectHomePage();
      } else {
        this.$dialog.message.error("Login Error, please try again.", {
          position: "top-right",
          timeout: 3000,
        });
      }
    } catch (error) {
      console.log("error, ", error);
      this.$dialog.message.error(
        error.response?.data?.detail || "Login Error, please try again.",
        {
          position: "top-right",
          timeout: 3000,
        }
      );
    }
  },
  methods: {
    ...mapMutations("user", ["loginSuccess"]),
    ...mapActions("user", ["loginSaml", "initCurrentOrgUser"]),
    redirectHomePage() {
      this.initCurrentOrgUser();
      setTimeout(() => {
        if (window.history.replaceState) {
          window.history.replaceState(null, "", null);
        }
        if (this.getCurrentUserData) {
          if (this.getCurrentUserData.user_type == "operator") {
            this.$router.replace({ name: "UntrackedZones" });
          } else if (this.getCurrentUserData.user_type == "admin") {
            window.location.replace(
              `${process.env.VUE_APP_3_BASE_URL_PATH}/dashboard`
            );
          } else {
            window.location.replace(
              `${process.env.VUE_APP_3_BASE_URL_PATH}/home`
            );
          }
        } else {
          window.location.replace(
            `${process.env.VUE_APP_3_BASE_URL_PATH}/home`
          );
        }
      }, 300);
    },
  },
});
</script>
<style scoped>
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  font-size: 20px;
  font-weight: bold;
}
</style>
