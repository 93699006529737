import { Point, Polygon } from "geojson";
import { Camera } from "./Camera";

export enum ParkingStatus {
  free = "free",
  unavailable = "unavailable",
  reserved = "reserved",
}

interface ParkingSpotBase {
  id: number;
  name: string | null;
  current_status: ParkingStatus;
  max_park_violation_alert_time_seconds: number | null;
  is_handicap_parking: boolean;
  is_status_marked_unknown: boolean;
  is_marked_uknown_spot_updates_enabled: boolean;
  is_lane_based_matching_logic_enabled: boolean;
  is_illegal_parking_spot: boolean;
  comment: string | null;
  requires_parking_permit_ids: Array<number> | null;
  disable_spot_payment: boolean;
  spot_payment_grace_period: number | null;
}

export interface ParkingSpot extends ParkingSpotBase {
  cameras: Array<Camera>;
  polygon: Polygon;
  gps_coordinates: Point;
  meta: Record<string, string>;
  updated_at: string;
  status_updated_at: string;
  floor: number;
  parking_lot_id: number;
  camera_id: number;

  // Read only fields
  is_status_unknown: boolean; // Auto-computed from other unknown fields
  is_status_untrackable: boolean; // Auto-computed from other unknown fields
  is_status_unknown_flip_flop: boolean;
  is_status_unknown_camera_offline: boolean;
  is_status_unknown_camera_inactive: boolean;
  is_status_unknown_parallel_parking: boolean;
  is_status_unknown_edge_device_offline: boolean;

  vehicle_parking_usage_anpr_lp_number: string | null;
  vehicle_parking_usage_anpr_record_id: number | null;
}

export interface ParkingSpotUpdate extends ParkingSpotBase {
  parking_lot_id: number;
}

export interface ParkingSpotInaccuracy {
  parking_lot_id: number;
  spot_id: number;
}

export interface ParkingSpotDetails {
  spot_id: number;
  spot_name?: string;
  meta?: Record<string, string>;
  lot_name: string;
  camera_id?: number;
  status: ParkingStatus;
  unknown_reason?: string;
  permits?: Array<string>;
  violation?: string;
  max_parking_time?: number;
  anpr_record_id?: number;
  license_plate_number?: string;
  vehicle_type?: string;
  vehicle_color?: string;
  vehicle_brand?: string;
  vehicle_region?: string;
  vehicle_country?: string;
  dwell_time?: number;
  parked_at: string;
  num_of_sessions: number;
  occupancy: number;
  avg_dwell_time: number;
  last_week_avg_dwell_time: number;
  longest_dwell_time: number;
  last_week_longest_dwell_time: number;
  shortest_dwell_time: number;
  last_week_shortest_dwell_time: number;
  occupancy_data: Array<number>;
}

interface AlertDetailsDiyApp {
  id: number;
  title?: string | null;
  alert_type_id?: number | null;
  organization_id?: number | null;
  parking_lot_id?: number | null;
  parking_spot_id?: number | null;
  parking_lot_name?: string | null;
  camera_name?: string | null;
  parking_spot_name?: string | null;
  image_path_url?: string | null;
  image_paths_urls?: Array<string> | null;
}

class ParkingHistoryDiyApp {
  parking_lot_id?: number | null;
  parking_spot_id?: number | null;
  parking_spot_name?: string | null;
  status?: ParkingStatus;
  unknown_status?: string | null;
  flipflop_status?: string | null;
  status_change_time?: number | null;
  status_changed_manually?: boolean | null;
  was_status_marked_unknown?: boolean | null;
  camera_id?: number | null;
  image_path_url?: string | null;
  exit_image_path_url?: string | null;
  created_at?: string | null;
}

export interface ActivityAlertssDiyAppResponseV2
  extends AlertDetailsDiyApp,
    ParkingHistoryDiyApp {
  local_date?: string;
}
