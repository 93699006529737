var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pt-0",staticStyle:{"margin-top":"10px"}},[_c('v-card',[_c('v-card-title',[_vm._v("Vehicle Matching ANPR Accuracy")]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.chart.duration,"item-text":"label","item-value":"value","menu-props":{ maxHeight: '400' },"label":"Select Duration","hint":"Select a Duration, or choose a custom date range","persistent-hint":"","role":"combobox","aria-label":"Select a duration, or choose a custom date range"},on:{"change":_vm.setChartDates},model:{value:(_vm.chart.selectedDuration),callback:function ($$v) {_vm.$set(_vm.chart, "selectedDuration", $$v)},expression:"chart.selectedDuration"}})],1),_c('v-spacer')],1),_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12","md":"2","sm":"6"}},[_c('v-row',{staticClass:"align-center ml-2"},[_c('span',{staticClass:"v-label theme--light mr-2"},[_vm._v("Continuous")]),_c('v-switch',{staticClass:"ma-2",attrs:{"hide-details":"","inset":"","disabled":!(_vm.chart.startDate && _vm.chart.endDate),"role":"switch","aria-label":"Toggle between Continuous and Sliced time slicing"},model:{value:(_vm.chart.timeSliced),callback:function ($$v) {_vm.$set(_vm.chart, "timeSliced", $$v)},expression:"chart.timeSliced"}}),_c('span',{staticClass:"v-label theme--light"},[_vm._v("Sliced")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","aria-haspopup":"dialog"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","prepend-icon":"mdi-calendar","readonly":"","disabled":!(_vm.chart.selectedDuration >= 1),"role":"combobox","aria-label":"Start Date"},model:{value:(_vm.chart.startDate),callback:function ($$v) {_vm.$set(_vm.chart, "startDate", $$v)},expression:"chart.startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.chart.showStartDate),callback:function ($$v) {_vm.$set(_vm.chart, "showStartDate", $$v)},expression:"chart.showStartDate"}},[_c('v-date-picker',{attrs:{"max":_vm.todaysDate,"role":"dialog","aria-labelledby":"start-date-label"},on:{"input":function($event){_vm.chart.showStartDate = false},"change":_vm.setChartGranularity},model:{value:(_vm.chart.startDate),callback:function ($$v) {_vm.$set(_vm.chart, "startDate", $$v)},expression:"chart.startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","aria-haspopup":"dialog"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","prepend-icon":"mdi-calendar","readonly":"","disabled":!(_vm.chart.selectedDuration >= 1),"role":"combobox","aria-label":"End Date"},model:{value:(_vm.chart.endDate),callback:function ($$v) {_vm.$set(_vm.chart, "endDate", $$v)},expression:"chart.endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.chart.showEndDate),callback:function ($$v) {_vm.$set(_vm.chart, "showEndDate", $$v)},expression:"chart.showEndDate"}},[_c('v-date-picker',{attrs:{"min":_vm.chart.startDate,"max":_vm.todaysDate,"role":"dialog","aria-labelledby":"end-date-label"},on:{"input":function($event){_vm.chart.showEndDate = false},"change":_vm.setChartGranularity},model:{value:(_vm.chart.endDate),callback:function ($$v) {_vm.$set(_vm.chart, "endDate", $$v)},expression:"chart.endDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"6"}},[_c('v-menu',{ref:"starttimemenuacclots",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.chart.startTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","aria-haspopup":"dialog"},on:{"update:returnValue":function($event){return _vm.$set(_vm.chart, "startTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.chart, "startTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.timeConvert(_vm.chart.startTime),"label":"Set Start time","prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","disabled":!(_vm.chart.startDate && _vm.chart.endDate),"role":"combobox","aria-label":"Set Start Time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.chart.showStartTime),callback:function ($$v) {_vm.$set(_vm.chart, "showStartTime", $$v)},expression:"chart.showStartTime"}},[(_vm.chart.showStartTime)?_c('v-time-picker',{attrs:{"full-width":"","role":"dialog","aria-labelledby":"start-time-picker-label"},on:{"click:minute":function($event){return _vm.$refs.starttimemenuacclots.save(_vm.chart.startTime)}},model:{value:(_vm.chart.startTime),callback:function ($$v) {_vm.$set(_vm.chart, "startTime", $$v)},expression:"chart.startTime"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"6"}},[_c('v-menu',{ref:"endtimemenuacclots",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.chart.endTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","aria-haspopup":"dialog"},on:{"update:returnValue":function($event){return _vm.$set(_vm.chart, "endTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.chart, "endTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.timeConvert(_vm.chart.endTime),"label":"Set End time","prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","disabled":!(_vm.chart.startDate && _vm.chart.endDate),"role":"combobox","aria-label":"Set End Time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.chart.showEndTime),callback:function ($$v) {_vm.$set(_vm.chart, "showEndTime", $$v)},expression:"chart.showEndTime"}},[(_vm.chart.showEndTime)?_c('v-time-picker',{attrs:{"full-width":"","role":"dialog","aria-labelledby":"end-time-picker-label"},on:{"click:minute":function($event){return _vm.$refs.endtimemenuacclots.save(_vm.chart.endTime)}},model:{value:(_vm.chart.endTime),callback:function ($$v) {_vm.$set(_vm.chart, "endTime", $$v)},expression:"chart.endTime"}}):_vm._e()],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.parkingLots.items,"item-text":"name","item-value":"id","label":"Parking Lot","clearable":""},on:{"change":_vm.fetchLotCameras},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.id)+" - "+_vm._s(data.item.name)+" ")]}}]),model:{value:(_vm.chart.lotId),callback:function ($$v) {_vm.$set(_vm.chart, "lotId", $$v)},expression:"chart.lotId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.cameras.items,"item-text":"name","item-value":"id","label":"Camera","hint":"Filter by Top Matching Candidate Camera or Verified Candidate Camera","persistent-hint":"","clearable":"","disabled":_vm.chart.lotId == null ||
              (_vm.cameras.items && _vm.cameras.items.length == 0)},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.id)+" - "+_vm._s(data.item.name)+" ")]}}]),model:{value:(_vm.chart.cameraId),callback:function ($$v) {_vm.$set(_vm.chart, "cameraId", $$v)},expression:"chart.cameraId"}})],1),(_vm.filterSpot)?_c('v-col',{attrs:{"cols":"12","md":"2","sm":"6"}},[_c('span',{staticClass:"text-center font-weight-medium text-body-1"},[_vm._v("Parking Spot ID: "+_vm._s(_vm.filterSpot))])]):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.fetchRecords(1)}}},[_c('v-icon',[_vm._v("mdi-filter-outline")]),_vm._v("Submit")],1),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.refreshPage}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v("Refresh")],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"chartWrapper"},[_c('div',{staticClass:"chartAreaWrapper"},[_c('div',{staticClass:"chartAreaWrapper2"},[_c('canvas',{attrs:{"id":"trend-chart","width":"1600","height":"600"}})])])])])],1),_c('v-row',[(_vm.pagination.itemsTotalCount > 0)?_c('v-col',[_c('p',{staticClass:"font-weight-bold ma-0"},[_vm._v(" Showing "+_vm._s(_vm.pagination.page * _vm.pagination.itemsPerPage - _vm.pagination.itemsPerPage + 1)+" to "+_vm._s(_vm.pagination.page * _vm.pagination.itemsPerPage > _vm.pagination.itemsTotalCount ? _vm.pagination.itemsTotalCount : _vm.pagination.page * _vm.pagination.itemsPerPage)+" of "+_vm._s(_vm.pagination.itemsTotalCount)+" Vehicle Records ")])]):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('p',{staticClass:"font-weight-bold ma-0"},[_vm._v(" "+_vm._s(_vm.matchingAccuracyText)+" ")])])],1),_c('v-row',{staticClass:"align-center justify-center"},[_c('v-col',{attrs:{"cols":"2","md":"1"}},[_c('v-select',{staticClass:"elevation-1",attrs:{"items":[
              {
                text: 'Greater Than',
                value: 'greater_than',
                icon: 'mdi-greater-than',
              },
              {
                text: 'Less Than',
                value: 'less_than',
                icon: 'mdi-less-than',
              },
              { text: 'Equal To', value: 'equal_to', icon: 'mdi-equal' } ],"outlined":"","hide-details":"","dense":""},on:{"change":_vm.filterByRecordIdSetBy},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-icon',[_vm._v(_vm._s(data.item.icon))])]}},{key:"selection",fn:function(data){return [_c('v-icon',[_vm._v(_vm._s(data.item.icon))])]}}]),model:{value:(_vm.filters.record_id_match),callback:function ($$v) {_vm.$set(_vm.filters, "record_id_match", $$v)},expression:"filters.record_id_match"}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"10","sm":"3","md":"3","lg":"2"}},[_c('v-text-field',{attrs:{"placeholder":"Filter by Record ID","value":_vm.filters.record_id,"rules":[_vm.validNumberOrNull],"type":"number","single-line":"","clearable":""},on:{"input":function($event){_vm.filters.record_id = $event !== '' ? $event : null},"keypress":function($event){return _vm.isNumber($event)},"keydown":_vm.filterByRecordId,"click:clear":function($event){return _vm.clearFilter('record')}}})],1),_c('v-col',{attrs:{"cols":"2","md":"1"}},[_c('v-select',{staticClass:"elevation-1",attrs:{"items":[
              {
                text: 'Greater Than',
                value: 'greater_than',
                icon: 'mdi-greater-than',
              },
              {
                text: 'Less Than',
                value: 'less_than',
                icon: 'mdi-less-than',
              },
              { text: 'Equal To', value: 'equal_to', icon: 'mdi-equal' } ],"outlined":"","hide-details":"","dense":""},on:{"change":_vm.filterByTopMatchingScoreSetBy},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-icon',[_vm._v(_vm._s(data.item.icon))])]}},{key:"selection",fn:function(data){return [_c('v-icon',[_vm._v(_vm._s(data.item.icon))])]}}]),model:{value:(_vm.filters.top_matching_score_match),callback:function ($$v) {_vm.$set(_vm.filters, "top_matching_score_match", $$v)},expression:"filters.top_matching_score_match"}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"10","sm":"3","md":"3","lg":"2"}},[_c('v-text-field',{attrs:{"placeholder":"Filter by Matching Score","value":_vm.filters.top_matching_score,"rules":[_vm.validNumberOrNull],"type":"number","single-line":"","clearable":""},on:{"input":function($event){_vm.filters.top_matching_score = $event !== '' ? $event : null},"keydown":_vm.filterByRecordId,"click:clear":function($event){return _vm.clearFilter('score')}}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"10","sm":"6","md":"3","lg":"2"}},[_c('v-text-field',{attrs:{"placeholder":"Filter by LPR ID","value":_vm.filters.lpr_id,"rules":[_vm.validNumberOrNull],"type":"number","single-line":"","clearable":""},on:{"input":function($event){_vm.filters.lpr_id = $event !== '' ? $event : null},"keypress":function($event){return _vm.isNumber($event)},"keydown":_vm.filterByRecordId,"click:clear":function($event){return _vm.clearFilter('lpr')}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","placeholder":"Filter by License Plate","value":_vm.filters.license_plate_number,"rules":[
              function (v) { return v == null ||
                (v && v.length >= 1 && v.length < 11) ||
                'License Plate must be between 1 to 10 characters long.'; },
              function (v) { return v == null || _vm.validString; } ],"single-line":"","clearable":""},on:{"input":function($event){_vm.filters.license_plate_number =
                $event !== '' ? $event.toUpperCase() : null},"keydown":_vm.filterByRecordId,"click:clear":function($event){return _vm.clearFilter('license_plate_number')}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","placeholder":"Filter by Spot Id","value":_vm.filters.spot_id,"rules":[_vm.validNumberOrNull],"type":"number","single-line":"","clearable":""},on:{"input":function($event){_vm.filters.spot_id = $event !== '' ? $event : null},"keypress":function($event){return _vm.isNumber($event)},"keydown":_vm.filterByRecordId,"click:clear":function($event){return _vm.clearFilter('spot_id')}}})],1),_c('v-spacer')],1),_c('v-row',{staticClass:"align-center justify-center"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.matchingStatuses,"item-text":"text","item-value":"value","menu-props":{ maxHeight: '400' },"label":"Matching Status","hint":"Filter by matching status","persistent-hint":"","clearable":""},on:{"change":function($event){return _vm.fetchRecords(1)}},model:{value:(_vm.filters.matching_status),callback:function ($$v) {_vm.$set(_vm.filters, "matching_status", $$v)},expression:"filters.matching_status"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":[null, true, false],"item-text":"label","item-value":"value","menu-props":{ maxHeight: '400' },"label":"Verified","hint":"Filter by verified status","persistent-hint":"","clearable":""},on:{"change":function($event){return _vm.fetchRecords(1)}},model:{value:(_vm.filters.verified),callback:function ($$v) {_vm.$set(_vm.filters, "verified", $$v)},expression:"filters.verified"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":[
              { label: 'MATCH', value: true },
              { label: 'NO MATCH', value: false } ],"item-text":"label","item-value":"value","menu-props":{ maxHeight: '400' },"label":"AI Predicted","hint":"Filter by AI predicted status","persistent-hint":"","clearable":""},on:{"change":function($event){return _vm.fetchRecords(1)}},model:{value:(_vm.filters.ai_predicted),callback:function ($$v) {_vm.$set(_vm.filters, "ai_predicted", $$v)},expression:"filters.ai_predicted"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":[null, true, false],"item-text":"label","item-value":"value","menu-props":{ maxHeight: '400' },"label":"AI Prediction Correctness","hint":"Filter by AI matched correctly status","persistent-hint":"","clearable":""},on:{"change":function($event){return _vm.fetchRecords(1)}},model:{value:(_vm.filters.ai_matched_correctly),callback:function ($$v) {_vm.$set(_vm.filters, "ai_matched_correctly", $$v)},expression:"filters.ai_matched_correctly"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":[
              { label: 'True Positive', value: 'true_positive' },
              { label: 'True Negative', value: 'true_negative' },
              { label: 'False Positive', value: 'false_positive' },
              { label: 'False Negative', value: 'false_negative' } ],"item-text":"label","item-value":"value","menu-props":{ maxHeight: '400' },"label":"Matching Type","hint":"Filter by matching type","persistent-hint":"","clearable":""},on:{"change":_vm.filterByMatchingType},model:{value:(_vm.filters.matching_type),callback:function ($$v) {_vm.$set(_vm.filters, "matching_type", $$v)},expression:"filters.matching_type"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.resetFilters}},[_c('v-icon',[_vm._v("mdi-filter-remove-outline")]),_vm._v("Reset")],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.vehicleMatchingRecords,"items-per-page":_vm.pagination.itemsPerPage,"hide-default-footer":"","no-data-text":"No matches found"},on:{"update:options":_vm.onSortChange,"click:row":_vm.showRecordDetails,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.hideRecordDetails.apply(null, arguments)},"click:outside":_vm.hideRecordDetails,"close-form":_vm.hideRecordDetails},scopedSlots:_vm._u([{key:"item.lpr_id",fn:function(ref){
            var item = ref.item;
return [(item.anpr_record != null)?_c('span',[_vm._v(_vm._s(item.anpr_record.lpr_entry_record_id || "NA")+"/"+_vm._s(item.anpr_record.lpr_exit_record_id || "NA"))]):_c('span',[_vm._v("-")])]}},{key:"item.is_verified",fn:function(ref){
            var item = ref.item;
return [(item.is_verified)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle")])]}},{key:"item.ai_matched_index",fn:function(ref){
            var item = ref.item;
return [(item.ai_matched_index != null)?_c('span',{attrs:{"color":"green"}},[_vm._v("MATCH")]):_c('span',{attrs:{"color":"red"}},[_vm._v("NO MATCH")])]}},{key:"item.ai_matched_correctly",fn:function(ref){
            var item = ref.item;
return [(item.is_verified)?_c('span',[(item.ai_matched_correctly === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle")])],1):_vm._e()]}},{key:"item.top_matching_confidence_score",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.top_matching_confidence_score)+" ")]}},{key:"item.created_at",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocalTimestamp")(item.created_at))+" ")]}}])})],1)],1),_c('v-row',{attrs:{"dense":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-pagination',{attrs:{"length":Math.ceil(_vm.pagination.itemsTotalCount / _vm.pagination.itemsPerPage),"total-visible":"7"},on:{"input":_vm.fetchRecords},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"prefix":"Page No.","single-line":"","outlined":"","dense":"","type":"number","hide-details":"auto"},on:{"input":_vm.fetchRecords},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", _vm._n($$v))},expression:"pagination.page"}})],1)],1)],1)],1),_c('v-dialog',{staticStyle:{"min-height":"80vh"},attrs:{"max-width":"960px"},model:{value:(_vm.showDetailsPopup),callback:function ($$v) {_vm.showDetailsPopup=$$v},expression:"showDetailsPopup"}},[(_vm.selectedRecord != null)?_c('vehicle-matching-anpr-record-details',{attrs:{"recordDetails":_vm.selectedRecord,"candidateDetails":_vm.selectedRecordCandidateDetails},on:{"switch-next-record":function($event){return _vm.switchSelectedRecord(1)},"switch-prev-record":function($event){return _vm.switchSelectedRecord(-1)},"close-form":_vm.hideRecordDetails,"refresh-data":_vm.fetchRecords}}):_vm._e()],1),_c('v-overlay',{attrs:{"value":_vm.chart.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }