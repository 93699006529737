








































































































































































































































































import Vue from "vue";
import api from "@/api/api";
import { LprBlacklistWhitelistRecord, ParkingLot } from "@/api/models";
import LprAlertListRecordForm from "@/components/forms/LprAlertListRecordForm.vue";
import { mdiTabUnselected } from "@mdi/js";

export default Vue.extend({
  name: "LprBlacklistWhitelist",

  props: {
    lotId: {
      required: false,
      type: Number,
    },
    parkingLotData: {
      required: false,
      type: Object as () => ParkingLot,
    },
  },

  components: {
    LprAlertListRecordForm,
  },

  data: () => ({
    isLoading: false,
    showLpAlertListForm: false,
    selectedLpAlertListRecord: null as LprBlacklistWhitelistRecord | null,
    lprBlacklistWhitelistHeaders: [
      { text: "ID", value: "temp_id" },
      { text: "License Plate", value: "license_plate" },
      { text: "Description", value: "description", sortable: false },
      { text: "LP Alert Type", value: "list_type" },
      { text: "Override Grace Period", value: "override_wait_time_secs" },
      { text: "Timestamp", value: "created_at" },
      { text: "Alert Enabled", value: "alert_enabled" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    lprBlacklistWhitelistData: [] as Array<LprBlacklistWhitelistRecord>,
    lpToBeAddedToList: null as string | null,

    filters: {
      parkingZones: {
        selectedId: null as number | null,
      },
      licensePlateNumber: "",
      dateMenu: {
        showMenu: false,
        value: [] as Array<string>,
      },
    },

    IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED:
      process.env.VUE_APP_IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED,
  }),

  mounted() {
    console.log(
      "IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED ",
      this.IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED
    );
    if (this.IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED) {
      this.lprBlacklistWhitelistHeaders = [
        ...this.lprBlacklistWhitelistHeaders.slice(0, 1),
        { text: "Parking Zone", value: "parking_zone_id" },
        ...this.lprBlacklistWhitelistHeaders.slice(1),
      ];
    }

    this.initData();

    let routeParkingZoneId = this.$route.query.parking_zone_id;
    console.log("Router parking zone id: ", routeParkingZoneId);
    if (
      routeParkingZoneId &&
      typeof routeParkingZoneId == "string" &&
      parseInt(routeParkingZoneId)
    ) {
      console.log("Setting zone: ", routeParkingZoneId);
      this.filters.parkingZones.selectedId = parseInt(routeParkingZoneId);
    }
  },

  computed: {
    parkingZonesItems(): Array<{ label: string; id: number }> {
      let lprItems = this.parkingLotData?.parking_zones
        .filter(
          (z) =>
            z.zone_type == "normal_spots_zone" ||
            z.zone_type == "lpr_counter_zone"
        )
        .map((z) => ({
          id: z.id,
          label: z.name || "Zone ID-" + z.id.toString(),
        }));
      return lprItems || ([] as Array<{ label: string; id: number }>);
    },

    hasAnAlwaysAllowedListEntry(): boolean {
      if (
        this.lprBlacklistWhitelistData?.filter(
          (lpr) => lpr.list_type === "always_allowed"
        ).length > 0
      ) {
        return true;
      }
      return false;
    },

    filteredRecords() {
      let filteredRecords = this.lprBlacklistWhitelistData;
      if (this.filters.licensePlateNumber) {
        filteredRecords = filteredRecords.filter((r) =>
          r.license_plate
            .toLowerCase()
            .includes(this.filters.licensePlateNumber.toLowerCase())
        );
      }
      if (this.filters.parkingZones.selectedId) {
        filteredRecords = filteredRecords.filter(
          (r) => r.parking_zone_id === this.filters.parkingZones.selectedId
        );
      }
      if (this.filters.dateMenu.value.length >= 2) {
        filteredRecords = filteredRecords.filter((r) => {
          let recordDate = new Date(r.created_at);
          return (
            recordDate >= new Date(`${this.filters.dateMenu.value[0]} 00:00`) &&
            recordDate <= new Date(`${this.filters.dateMenu.value[1]} 23:59`)
          );
        });
      }
      filteredRecords = filteredRecords.map((r, index) => {
        r.temp_id = index + 1;
        return r;
      });
      return filteredRecords;
    },
  },

  methods: {
    async initData() {
      this.isLoading = true;
      this.lprBlacklistWhitelistData = [];
      let listRecords = await api.getLprBlacklistWhitelist(this.lotId);
      if (listRecords && listRecords.length > 0) {
        this.lprBlacklistWhitelistData = listRecords;
      }
      this.isLoading = false;
    },

    capitalizeWords(text: string): string {
      return text
        .split("_")
        .map((w) => w.slice(0, 1).toUpperCase() + w.slice(1))
        .join(" ");
    },

    getHelpTextForLpAlertType(alertType: string): string {
      let helpTexts: Record<string, string> = {
        always_allowed:
          "Violation alert will be generated if any other LP is detected.",
        never_allowed:
          "Violation alert will be generated if this LP is detected.",
        watch_list: "Info alert will be generated if this LP detected.",
        vip_list: "Info alert will be generated if this LP is detected.",
      };
      return helpTexts[alertType] || this.capitalizeWords(alertType);
    },

    openRecordDetailsForm(selectedRecord: LprBlacklistWhitelistRecord) {
      this.selectedLpAlertListRecord = selectedRecord;
      this.showLpAlertListForm = true;
      console.log("clicked", selectedRecord, this.selectedLpAlertListRecord);
    },

    closeRecordDetailsForm() {
      this.showLpAlertListForm = false;
      this.selectedLpAlertListRecord = null;
    },

    async deleteLpFromList(lpRecord: LprBlacklistWhitelistRecord) {
      this.isLoading = true;
      let deletedRecord = await api.deleteLpFromBlacklistWhitelist(
        this.lotId,
        lpRecord.id
      );
      if (deletedRecord == null || typeof deletedRecord == "string") {
        this.$dialog.message.error(
          typeof deletedRecord == "string"
            ? deletedRecord
            : "Error, failed to delete LP from list. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } else {
        this.$dialog.message.info("Successfully deleted LPR Alert from list.", {
          position: "top-right",
          timeout: 3000,
        });
      }
      this.isLoading = false;
      this.initData();
    },

    clearLpDates() {
      this.filters.dateMenu.value = [];
    },

    onDatePickerFocus() {
      this.filters.dateMenu.showMenu = true;
      setTimeout(() => {
        document.getElementById("lpr-alerts-range-picker")?.focus();
        document
          .getElementById("lpr-alerts-range-picker")
          ?.addEventListener("keydown", (event) => {
            if (event.key === "Escape") {
              setTimeout(() => {
                document.getElementById("date-picker-next-focus")?.focus();
              }, 300);
            }
          });
      }, 300);
    },
  },
});
