






















import Vue from "vue";

enum ButtonVariant {
  FLAT = "flat",
  TEXT = "text",
  ELEVATED = "elevated",
  TONAL = "tonal",
  OUTLINED = "outlined",
  PLAIN = "plain",
}

interface ActionItems {
  label: string;
  action: string;
  variant?: ButtonVariant;
}

export default Vue.extend({
  name: "ConfirmActionDialog",

  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    actions: {
      type: Array as () => ActionItems[],
      required: true,
    },
  },
});
